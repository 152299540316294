import { useRef, useEffect, useState,Fragment, } from "react";
import { Popover, Transition, Listbox,Dialog,Disclosure } from '@headlessui/react';
import Modal from "./Modal";


const bookingsData = [
    {
        id: 1,
        BookingNumber : 2085319,
        service : "Pet Sitting",
        serviceType : "One-off",
        ProviderName: "Cameron Williamson",
        location : "Canberra, ACT",
        dates : "Feb 13 - 15",
        serviceStatus : "pending",
        estimatedTotal : "98.1",
        dogName:"max",
        dogBreed: "Labrador Retriever",
        dogImageUrl : "https://images.unsplash.com/photo-1623052940978-051d2c0fb4be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80",
        providerImageUrl : "https://images.unsplash.com/photo-1534180477871-5d6cc81f3920?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
    },
    {
        id: 2,
        BookingNumber : 2084317,
        service : "Pet Sitting",
        serviceType : "One-off",
        ProviderName: "Margaret R.",
        location : "Canberra, ACT",
        dates : "Feb 13 - 15",
        serviceStatus : "Booking success",
        estimatedTotal : "98.1",
        dogName:"max",
        dogBreed: "Labrador Retriever",
        dogImageUrl : "https://images.unsplash.com/photo-1623052940978-051d2c0fb4be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80",
        providerImageUrl : "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80"
    },
]
const filterStatus = [
    { name: 'All Status',status:false },
    { name: 'Payment Processing' },
    { name: 'Pending' },
    { name: 'Active' },
    { name: 'Approved' },
    { name: 'Paid' },
    { name: 'Completed' },
]
const filterUsers = [
    { name: 'All Users' },
    { name: 'Cameron Williamson' },
    { name: 'Margaret R.' },
]
const filterServices = [
    { name: 'All Services' },
    { name: 'Pet Hosting' },
    { name: 'Pet Sitting' },
    { name: 'Day Care' },
    { name: 'Dog Walking' },
    { name: 'Day Visits' },
]
const sortLists = [
    { name: 'Recently completed' },
    { name: 'Last updated' },
    { name: 'Upcoming' },
    { name: 'Lowest price' }
]

function Bookings(props){

    const modal =useRef();
    const [viewList,setViewList] =  useState(true);
    const [viewBooking,setViewBooking]  =  useState(true);
    const [selectBooking,setSelectBooking]  =  useState(false);
    const [screen,setScreen] = useState('');
    const [selectedBooking,setSelectedBooking] = useState(1);
    const [selected, setSelected] = useState([]);
    const [filterSelectedStatus,setFilterSelectedStatus] = useState([]);
    const [sortSelected,setSortSelected] = useState(sortLists[0]);
    let [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
            console.log(window.innerWidth);
            if(window.innerWidth < 640){
                setViewList( selectBooking ? false : true );
                setViewBooking(selectBooking ? true : false);
                setScreen('xs');
            }
            else if(window.innerWidth > 640 && window.innerWidth < 768){
                setViewList( selectBooking ? false : true );
                setViewBooking(selectBooking ? true : false);
                setScreen('sm');
            }
            else if(window.innerWidth > 768 && window.innerWidth < 1024){
                setViewList( selectBooking ? false : true );
                setViewBooking(selectBooking ? true : false);
                setScreen('md');
            }
            else if(window.innerWidth > 1024 && window.innerWidth < 1280){
                setViewList(true);
                setViewBooking(true);
                setSelectBooking(false);
                setScreen('lg');
            }
            else if(window.innerWidth > 1024){
                setViewList(true);
                setViewBooking(true);
                setSelectBooking(false);
                setScreen('above');
            }
        };

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const openModal = () =>{
        modal.current.openModal();
    }

    const handleViewBooking = (id) =>{
        if(screen == 'xs' || screen == 'sm' || screen == 'md'){
            setSelectBooking(true);
            setViewBooking(true);
            setViewList(false);
        }
        setSelectedBooking(id);
    }

    const handleCloseBooking = () =>{
        setSelectBooking(false);
        setViewBooking(false);
        setViewList(true);
    }

    const handleFilterSelectedStatus = (status) =>{
        //e.preventDefault();
        
        setFilterSelectedStatus({});
        let index = filterStatus.findIndex(statusData => statusData.name === status.name);
        filterStatus[index].status = !filterStatus[index].status;
        //console.log(filterSelectedStatus);
    }

    const ChevronDown = () =>{
        return (
                <span className="w-6 h-6 border border-smokey-violet flex items-center justify-center rounded-lg mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                </span>
        )
    }

    function closeFilterModal() {
        setIsOpen(false)
    }
    
    function openFilterModal() {
        setIsOpen(true)
    }

    const FilterDisclosure = (props) =>{
        let title = props.title;

        return (
            <Disclosure>
                {({ open }) => (
                    <>
                    <Disclosure.Button className="my-2 flex w-full justify-between rounded-lg bg-purple-100 px-0 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span className="font-manrope-m text-sm text-blue-1">{title}</span>
                        <span >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={"h-4 w-4 stroke-blue-1 "+(open ? 'rotate-180 transform' : '')}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-0 pt-1 pb-2 text-sm text-gray-500">
                        {props.children}
                    </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        );
    }

    const FilterModal = () => {
        return (
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeFilterModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed bottom-0 left-0 h-3/4 w-full overflow-y-auto ">
                    <div className="relative flex h-full items-center justify-center text-center w-full">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className=" w-full h-full  z-[100] transform  rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-manrope-sb leading-6 text-black-2 p-4 pb-2"
                        >
                            Filter
                        </Dialog.Title>
                            <div className="mt-0 h-[26.3em]  overflow-y-auto px-4">
                                <FilterDisclosure title={"Sort"} > 
                                    <ul>
                                        {sortLists.map((sort,id)=>
                                            <li key={"sort-"+id} className="p-2 px-1">
                                                <div className="flex items-center font-manrope-m text-sm text-blue-1">
                                                    <ChevronDown/>
                                                    <span>{sort.name}</span>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </FilterDisclosure>
                                <FilterDisclosure title={"Status"} > 
                                    <ul>
                                        {filterStatus.map((status,id)=>
                                            <li key={"status"+id} className="p-2 px-1" onMouseDown={(e) => handleFilterSelectedStatus(status)}>
                                                <div className="flex items-center font-manrope-m text-sm text-blue-1">
                                                    {status.status && <ChevronDown />}
                                                    <span>{status.name}</span>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </FilterDisclosure>
                                <FilterDisclosure title={"Users"} > 
                                    <ul>
                                        {filterUsers.map((status,id)=>
                                            <li key={"user-"+id} className="p-2 px-1">
                                                <div className="flex items-center font-manrope-m text-sm text-blue-1">
                                                    <ChevronDown/>
                                                    <span>{status.name}</span>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </FilterDisclosure>
                                <FilterDisclosure title={"Services"} > 
                                    <ul>
                                        {filterServices.map((status,id)=>
                                            <li key={"service-"+id} className="p-2 px-1">
                                                <div className="flex items-center font-manrope-m text-sm text-blue-1">
                                                    <ChevronDown/>
                                                    <span>{status.name}</span>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </FilterDisclosure>
                                
                            </div>
                            <div className="flex justify-between mb-0 mt-auto absolute bottom-0 left-0 w-full z-[30] gap-2 p-2">
                                    <button onClick={closeFilterModal} className="h-12 text-base font-manrope-sb w-full bg-yellow rounded-lg">Cancel</button>
                                    <button onClick={closeFilterModal} className="h-12 text-base font-manrope-sb w-full rounded-lg bg-blue-1 text-white ">Save</button>
                                </div>
                        </Dialog.Panel>
                        
                    </Transition.Child>
                    </div>
                </div>
                </Dialog>
            </Transition>
        );
    }

    const FilterList = (props) =>{
        return(
            <Popover className="relative w-full">
              <>
                <Popover.Button
                  className={"group inline-flex items-center justify-between px-3 py-2 h-10 font-manrope-m text-sm text-blue-1 rounded-lg bg-white-smoke border border-white-smoke w-full capitalize "}
                >
                  <span>{props.header}</span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </span>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel static  className="absolute left-1/2 z-10 mt-3 w-full max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="bg-white p-3">
                           {props.children} 
                        </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
          </Popover>
        );
    }

    const SortListBox = () =>{
        return(
            <Listbox value={sortSelected} onChange={setSortSelected}>
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left h-10 font-manrope-m text-sm text-blue-1 bg-white-smoke border border-white-smoke ">
                        <span className="block truncate">{sortSelected.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-orange-300">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {sortLists.map((sort, sortIdx) => (
                            <Listbox.Option
                                key={sortIdx}
                                className={({ active }) =>
                                    "relative cursor-default select-none py-2 px-4 font-manrope-m "+( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                                }
                            value={sort}
                            >
                            {({ selected }) => (
                                <>
                                <span className={"block truncate " +(selected ? 'font-medium' : 'font-normal')}>
                                    {sort.name}
                                </span>
                                </>
                            )}
                            </Listbox.Option>
                        ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        )
    }

    const BookingsFilterList = () =>{
        return(
            <>
                <div className="mt-4 hidden md:flex p-0">
                    <div className="w-3/4 p-0 flex  flex-col p-0 items-start">
                        <div>
                            <label className="font-manrope-m text-[15px] text-blue-1">Filter</label>
                        </div>
                        <div className="w-full flex items-end mt-1 p-0">
                            <div className="w-2/6 flex items-end relative z-[10] p-0 items-center">
                                <FilterList header={"status"}>
                                    <ul>
                                        {filterStatus.map((status,id)=>
                                            <li key={"status"+id} className="p-2 px-1" onMouseDown={(e) => handleFilterSelectedStatus(status)}>
                                                <div className="flex items-center font-manrope-m text-sm text-blue-1">
                                                    {status.status && <ChevronDown />}
                                                    <span>{status.name}</span>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </FilterList>
                            </div>
                            <div className="w-2/6 flex items-end px-2 relative z-[8]">
                                <FilterList header={"users"}>
                                    <ul>
                                        {filterUsers.map((status,id)=>
                                            <li key={"status"+id} className="p-2 px-1">
                                                <div className="flex items-center font-manrope-m text-sm text-blue-1">
                                                    <ChevronDown/>
                                                    <span>{status.name}</span>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </FilterList>
                            </div>
                            <div className="w-2/6 flex items-end relative z-[6]">
                                <FilterList header={"services"}>
                                    <ul>
                                        {filterServices.map((status,id)=>
                                            <li key={"status"+id} className="p-2 px-1">
                                                <div className="flex items-center font-manrope-m text-sm text-blue-1">
                                                    <ChevronDown/>
                                                    <span>{status.name}</span>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </FilterList>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/4 ml-4 relative z-[4]">
                        <label className="font-manrope-m text-[15px] text-blue-1">Sort</label>
                        <SortListBox/>
                    </div>
                </div>
                <div className=" px-5 mt-5">
                    <div className="flex justify-end item-center cursor-pointer md:hidden" onClick={openFilterModal}>
                        <span className="mr-2">Filter</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                            </svg>
                        </span>
                    </div>                        
                </div>
            </>
        )
    }

    const BookingsList = () => {

        const list = bookingsData.map((booking,i) =>
                <div 
                    className={"flex justify-between relative p-6 border-dashed border-2 rounded-xl mb-3 px-4 md:px-6 lg:px-7 cursor-pointer " + (selectedBooking == booking.id && (screen == "lg" || screen == "xl" || screen == "above") ? "bg-orange-10 border-orange" : "bg-white-smoke border-smokey-violet")} 
                    onClick={(e) => handleViewBooking(booking.id)}
                    key={i}
                >
                    <div className="flex mt-2 max-md:w-8/12 w-9/12">
                        <div>
                            <figure className="w-14 h-14 md:h-16 md:w-16">
                                <img className="h-full w-full object-cover rounded-full object-center" src={booking.providerImageUrl} alt="" />
                            </figure>
                        </div>
                        <div className="ml-3 w-10/12">
                            <p className="font-manrope-eb text-base text-blue-1 mb-1 p-0">{booking.service} — {booking.serviceType} <span className="capitalize">({booking.dogName})</span></p>
                            <p className="font-manrope-m text-sm text-blue-1 underline mb-2">{booking.ProviderName}</p>
                            <p className="text-blue-grafy font-manrope-m text-sm">{booking.dates}</p>
                        </div>
                    </div>
                    <div className="text-end max-md:w-5/12 w-3/12 absolute top-6 max-md:right-4 right-8 ">
                        <div className="mb-4">
                            {   
                                <span className={"font-manrope-m text-xs px-3 py-1 rounded-full w-max " +(booking.serviceStatus == "pending" ? "text-red-3  bg-red-3-10" : "bg-green-1-10 text-green-1")}
                                >
                                   {booking.serviceStatus}
                                </span>
                            }
                        </div>
                        <p className="text-gray-1 font-manrope-m text-xs my-1">Estimated Total</p>
                        <h3 className="font-manrope-sb text-[22px] text-blue-1 mt-0 p-0">${booking.estimatedTotal}</h3>
                    </div>
                    {
                        selectedBooking == booking.id &&                     
                        <div className="absolute top-0 -right-4 h-full items-center justify-center hidden lg:flex">
                            <div className="w-8 h-8 bg-orange rounded-full flex items-center justify-center">                                       
                                <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.65614 2.17422L12.9749 8.47422C13.0499 8.54922 13.1031 8.63047 13.1346 8.71797C13.1661 8.80547 13.1816 8.89922 13.1811 8.99922C13.1811 9.09922 13.1656 9.19297 13.1346 9.28047C13.1036 9.36797 13.0504 9.44922 12.9749 9.52422L6.65614 15.843C6.48114 16.018 6.26239 16.1055 5.99989 16.1055C5.73739 16.1055 5.51239 16.0117 5.32489 15.8242C5.13739 15.6367 5.04364 15.418 5.04364 15.168C5.04364 14.918 5.13739 14.6992 5.32489 14.5117L10.8374 8.99922L5.32489 3.48672C5.14989 3.31172 5.06239 3.09597 5.06239 2.83947C5.06239 2.58297 5.15614 2.36122 5.34364 2.17422C5.53114 1.98672 5.74989 1.89297 5.99989 1.89297C6.24989 1.89297 6.46864 1.98672 6.65614 2.17422Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                    }
                </div>
        )
        return(
            <div className="max-md:px-0 pr-4 w-full lg:w-8/12">
                {list}
            </div>
        )
    }

    const BookingView = () => {

        let selected = bookingsData.find(obj => {
            return obj.id === selectedBooking
          })
        return(
            <div className="w-full lg:w-4/12">
                <div className="border border-smokey-violet rounded-xl ">
                    <div className="p-3 border-b  border-shuttle-gray-20 flex">
                        {
                            selectBooking &&
                            <div className="flex items-center mr-2">
                                <button className="text-blue-1" onClick={handleCloseBooking}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                    </svg>
                                </button>
                            </div>
                        }
                        <div className="ml-2">
                            <h2 className="font-manrope-m text-[15px] text-blue-1">Booking</h2>
                        </div>
                    </div>
                    <div className="pb-2">
                        <ul role="list" className=" divide-y divide-shuttle-gray-20">
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="ml-2 py-3">
                                    <p className="font-manrope-r text-sm text-gray-1">{selected.serviceType}</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1 capitalize">{selected.service}</p>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="ml-2 py-3">
                                    <p className="font-manrope-r text-sm text-gray-1">Date</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1 capitalize">{selected.dates}</p>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="ml-2 py-3">
                                    <p className="font-manrope-r text-sm text-gray-1 mb-1">Status</p>
                                    <p className={"font-manrope-sb text-sm px-2 rounded-full capitalize "+(selected.serviceStatus == "pending" ? "text-red-3  bg-red-3-10" : "bg-green-1-10 text-green-1")}>
                                        {selected.serviceStatus}
                                    </p>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="ml-3 py-3">
                                    <p className="font-manrope-r text-sm text-gray-1">Where</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1">Acton, ACT <span className="text-xs">(3.24km away)</span></p>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0 relative">
                                <div className="absolute top-3 right-3">
                                    <a href="#" className="text-hanblue font-manrope-m text-xs underline">View Photos</a>
                                </div>
                                <div className="py-3 ml-3">
                                    <span className="text-gray-1 font-manrope-m text-xs">With</span>
                                    <div className="flex mt-2">
                                        <figure className="w-14 h-14 md:h-16 md:w-16">
                                            <img className="h-full w-full object-cover rounded-full object-center" src={selected.dogImageUrl} alt="" />
                                        </figure>
                                        <div className="ml-3">
                                            <p className="font-manrope-sb text-[15px] text-blue-1">{selected.dogName}</p>
                                            <p className="font-manrope-r text-sm text-blue-1-60">{selected.dogBreed}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0 relative">
                                <div className="absolute top-3 right-3">
                                    <a href="#" className="text-hanblue font-manrope-m text-xs underline capitalize">Chat with { props.userType == "owner" ? "sitter" : "owner" }</a>
                                </div>
                                <div className="py-3 ml-3">
                                    <span className="text-gray-1 font-manrope-m text-xs capitalize">{ props.userType == "owner" ? "sitter" : "owner" }</span>
                                    <div className="flex mt-2">
                                        <figure className="w-14 h-14 md:h-16 md:w-16">
                                            <img className="h-full w-full object-cover rounded-full object-center" src={selected.providerImageUrl} alt="" />
                                        </figure>
                                        <div className="ml-3">
                                            <p className="font-manrope-sb text-[15px] text-blue-1">{selected.ProviderName}</p>
                                            <p className="font-manrope-r text-sm text-blue-1-60">{selected.location}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="ml-3 py-3">
                                    <p className="font-manrope-r text-sm text-gray-1">Payment & Cancellation Policy</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1">Flexible Policy</p>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="ml-3 py-3">
                                    <p className="font-manrope-r text-sm text-gray-1">Booking Number</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1">{selected.BookingNumber}</p>
                                </div>
                            </li>
                            <li className="first:pt-0 last:pb-0">
                                <div className="bg-yellow  py-6 relative cursor-pointer block" onClick={openModal}>
                                    <div className="px-7">
                                        <h5 className="font-manrope-m text-sm text-blue-1 mb-1">Estimated Total</h5>
                                        <a type="button" className="text-blue-1 underline flex items-center text-hanblue text-sm">
                                            View Details
                                            <span className="ml-1 mt-1">
                                                <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.32807 7.91289L4.48745 4.76289C4.52495 4.72539 4.55157 4.68477 4.56732 4.64102C4.58307 4.59727 4.59082 4.55039 4.59057 4.50039C4.59057 4.45039 4.58282 4.40352 4.56732 4.35977C4.55182 4.31602 4.5252 4.27539 4.48745 4.23789L1.32807 1.07852C1.24057 0.991016 1.1312 0.947266 0.999946 0.947266C0.868695 0.947266 0.756196 0.994141 0.662446 1.08789C0.568696 1.18164 0.521821 1.29102 0.521821 1.41602C0.521821 1.54102 0.568696 1.65039 0.662446 1.74414L3.4187 4.50039L0.662446 7.25664C0.574945 7.34414 0.531196 7.45202 0.531196 7.58027C0.531196 7.70852 0.578071 7.81939 0.671821 7.91289C0.765571 8.00664 0.874945 8.05352 0.999946 8.05352C1.12495 8.05352 1.23432 8.00664 1.32807 7.91289Z" fill="#386CB9"/>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="absolute top-0 right-10 h-full flex items-center">
                                        <h3 className="text-2xl text-blue-1 font-manrope-b">$98.1</h3>
                                    </div>
                                </div>
                                {
                                    props.userType == 'provider' && 
                                        <div className="w-full px-3">
                                            <button className="h-11 bg-green text-white w-full rounded-full my-2 mt-4">Accept Request</button>
                                        </div>
                                }
                                <div className="flex my-3 border-none px-3">
                                    <button className="w-1/2 h-11 bg-primary rounded-full text-white flex items-center justify-center font-manrope-b text-base ">
                                        <span>           
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.28394 14H14.2839V12H6.28394V14ZM6.28394 11H18.2839V9H6.28394V11ZM6.28394 8H18.2839V6H6.28394V8ZM2.28394 22V4C2.28394 3.45 2.47994 2.979 2.87194 2.587C3.26327 2.19567 3.73394 2 4.28394 2H20.2839C20.8339 2 21.3049 2.19567 21.6969 2.587C22.0883 2.979 22.2839 3.45 22.2839 4V16C22.2839 16.55 22.0883 17.021 21.6969 17.413C21.3049 17.8043 20.8339 18 20.2839 18H6.28394L2.28394 22ZM4.28394 17.175L5.45894 16H20.2839V4H4.28394V17.175Z" fill="white"/>
                                            </svg>
                                        </span>
                                        <span className="ml-1">
                                            Chat Now
                                        </span>
                                    </button>
                                    <button className="text-red h-11 w-1/2">Cancel Booking </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="w-full lg:w-9/12 mx-auto sm:w-11/12 px-2 md:px-0 max-md:mt-2 md:mt-10">
            <div className="max-md:mt-0 mt-10">
                <h3 className="font-manrope-m text-2xl text-blue-1">Bookings</h3>
                <div>
                    <BookingsFilterList/>
                </div>
                <div className="w-full flex gap-4 max-md:mt-6  mt-12">
                    { viewList && <BookingsList/> }
                    { viewBooking && <BookingView/> }
                </div>
            </div>
            <Modal ref={modal}/>
            <div>
                <FilterModal></FilterModal>
            </div>
        </div>
    )
}
export default Bookings;