import { Link } from "react-router-dom";


function Footer(){
    return (
        <footer className=" mt-24">
            <div className=" w-32 h-32 mx-auto -mb-16 bg-YInMn_Blue rounded-full p-1 pt-0">
                <img src="icons/doggo-days-logo.png" className=" w-full h-full " alt="doggo_logo"/>
            </div>
            <div className="bg-YInMn_Blue pb-8 pt-16">
                <div className="text-center pt-9 pb-9">
                    <div className="max-md:space-x-0 max-md:space-y-5 text-white md:space-x-10  lg:space-x-20 mb-10 font-manrope-b">
                        <Link to="/"><a className="max-md:block max-md:w-full max-md:mb-3 normal-case">Home</a></Link>
                        <Link to="/about"><a className="max-md:block max-md:w-full max-md:mb-3 normal-case">About</a></Link>
                        <Link to="/services"><a className="max-md:block max-md:w-full max-md:mb-3 normal-case">Services</a></Link>
                        <Link to="/events"><a className="max-md:block max-md:w-full max-md:mb-3 normal-case">Events</a></Link>
                        <Link to="/providers"><a className="max-md:block max-md:w-full max-md:mb-3 normal-case">Providers</a></Link>
                        <Link to="/contact-us"><a className="max-md:block max-md:w-full max-md:mb-3 normal-case">Contact us</a></Link>
                    </div>
                    <p className="max-md:w-full max-md:px-5 md:w-3/5 xl:w-2/5 mx-auto font-manrope-l text-sm text-white mt-5 leading-relaxed">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
                <div className="max-md:block max-md:space-x-0 text-center w-full flex justify-center text-white space-x-10 mb-5">
                    <div className="max-md:w-full max-md:mb-5">
                        <a>Privacy Policy | Terms and Conditions</a>
                    </div>
                    <div className="max-md:justify-center max-md:w-full  flex items-center ">
                        <div>© 2023 Doggo Days. Powered by </div>
                        <div className="h-4 ml-2 mt-1 w-max">
                            <img src="/images/planetmedia.png" className="w-full h-full object-contain" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;