import { useState, useRef } from 'react';
import { Listbox,Combobox } from '@headlessui/react';

const petType = [
    { id: 1, name: 'Dogs' }
  ]
const petBreed = [
    { id: 1, name: 'German shepherd' },
    { id: 2, name: 'Bulldog' },
    { id: 3, name: 'Labrador retriever' },
    { id: 4, name: 'French bulldog' },
    { id: 5, name: 'Siberian husky' },
  ]
const petLikes = [
    { id: 1, name: 'Walks' },
    { id: 2, name: 'Bounding' },
    { id: 3, name: 'Eating' },
    { id: 4, name: 'Chasing' },
    { id: 5, name: 'Giving you kisses' },
    { id: 6, name: 'Treats' },
    { id: 7, name: 'The dog park' },
    { id: 8, name: 'Fetch' },
  ]
  const petDislikes = [
    { id: 1, name: 'Hugging' },
    { id: 2, name: "Getting in a dog's face and personal space" },
    { id: 3, name: 'Not letting a dog sniff and explore on walks' },
    { id: 4, name: 'Lack of routine and rules' },
    { id: 5, name: 'Yelling and harsh punishment' },
    { id: 6, name: ' Dressing them up' },
  ]



function PetRegistrationDetails (){

    const [selectedPet, setSelectedPet] = useState({});
    const [selectedBreed, setSelectedBreed] = useState({});
    const [selectedLikes, setSelectedLikes] = useState([]);
    const [selectedDislikes, setSelectedDislikes] = useState([]);
    const [query, setQuery] = useState('');
    const [queryDislikes, setQueryDislikes] = useState('');
    const fileRef= useRef();
    const medicalFileRef= useRef();

    const fileUpload = (e) =>{
        fileRef.current.click();
    }

    const medicalFileUpload =(e) =>{
        medicalFileRef.current.click();
    }

    const  handleRemoveLikes = (like) =>{
        let likes = [...selectedLikes]
        let filtered = selectedLikes.filter(function(el) { return el.id != like.id; });
        setSelectedLikes(filtered);
    }

    const  handleRemoveDislikes = (dislike) =>{
        let dislikes = [...selectedDislikes]
        let filtered = selectedDislikes.filter(function(el) { return el.id != dislike.id; });
        setSelectedDislikes(filtered);
    }

    const handleAddLikes = (newlike) =>{
        let likes = [...selectedLikes];
        let filteredlist = selectedLikes.filter(function(el) { return el.name.toLowerCase() != newlike.toLowerCase() });
        filteredlist.push({"id":selectedLikes.length + 1 ,"name" : newlike});
        setQuery("");
        setSelectedLikes(filteredlist);
    }

    const handleAddDislikes = (newDislike) =>{
        let likes = [...selectedDislikes];
        let filteredlist = selectedDislikes.filter(function(el) { return el.name.toLowerCase() != newDislike.toLowerCase() });
        filteredlist.push({"id":selectedDislikes.length + 1 ,"name" : newDislike});
        setQueryDislikes("");
        setSelectedDislikes(filteredlist);
    }

    const filteredLikes =
    query === ''
      ? petLikes
      : petLikes.filter((like) => {
          return like.name.toLowerCase().includes(query.toLowerCase())
        });

    const filteredDislikes =
        queryDislikes === ''
          ? petDislikes
          : petDislikes.filter((dislikes) => {
              return dislikes.name.toLowerCase().includes(queryDislikes.toLowerCase())
            });



    return(
        <>
            <div className="w-full mb-8">
                <label className="font-manrope-m text-base text-blue-1 capitalize">Name*</label>
                <input type="text" className="h-11 w-full bg-white-smoke border border-gray-3 rounded-lg px-3 mt-2 focus:ring-offset-0 focus:ring-0" />
            </div>
            <div className="w-full mb-8 relative z-[10]">
                <label className="font-manrope-m text-base text-blue-1 ">Pet type*</label>
                <Listbox
                    value={selectedPet}
                    onChange={setSelectedPet}
                    name="petType"
                >
                    <Listbox.Button
                        className="relative z-[10] h-11 w-full bg-white-smoke border border-gray-3 rounded-lg px-3 mt-2 text-start"                                            
                    >
                        <span className="block truncate">{selectedPet.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </span>
                    </Listbox.Button>
                    <Listbox.Options
                        className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                    {petType.map((Pet) => (
                        <Listbox.Option 
                            key={Pet.id} 
                            value={Pet}
                            className={({ active }) =>
                            "relative cursor-default select-none py-2 pl-3  pr-4 "+ ( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                            }
                        >
                        {Pet.name}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Listbox>
            </div>
            <div className="w-full mb-8 relative z-[8]">
                <label className="font-manrope-m text-base text-blue-1 capitalize">Breed*</label>
                <Listbox
                    value={selectedBreed}
                    onChange={setSelectedBreed}
                >
                    <Listbox.Button
                        className="relative  h-11 w-full bg-white-smoke border border-gray-3 rounded-lg px-3 mt-2 text-start"                                            
                    >
                        <span className="block truncate">{selectedBreed.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </span>
                    </Listbox.Button>
                    <Listbox.Options
                        className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                    {petBreed.map((Pet) => (
                        <Listbox.Option 
                            key={Pet.id} 
                            value={Pet}
                            className={({ active }) =>
                            "relative cursor-default select-none py-2 pl-3  pr-4 font-manrope-m text-base "+ ( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                            }
                        >
                        {Pet.name}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Listbox>
            </div>
            <div className="w-full mb-8 relative z-[6]">
                <label className="font-manrope-m text-base text-blue-1 ">Medical information</label>
                <textarea rows="5" type="text" placeholder="Type Medical Information...." className="w-full bg-white-smoke  caret-white-smoke font-manrope-m text-sm placeholder-black-2-20 border border-gray-3 rounded-lg px-4 py-4 mt-2 focus:ring-offset-0 focus:ring-0" />
                <input type="file" ref={medicalFileRef} className="hidden"/> 
                <div className="absolute bottom-5 right-5">
                    <span onClick={medicalFileUpload}>                                               
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5 6V17.5C16.5 18.5609 16.0786 19.5783 15.3284 20.3284C14.5783 21.0786 13.5609 21.5 12.5 21.5C11.4391 21.5 10.4217 21.0786 9.67157 20.3284C8.92143 19.5783 8.5 18.5609 8.5 17.5V5C8.5 4.33696 8.76339 3.70107 9.23223 3.23223C9.70107 2.76339 10.337 2.5 11 2.5C11.663 2.5 12.2989 2.76339 12.7678 3.23223C13.2366 3.70107 13.5 4.33696 13.5 5V15.5C13.5 15.7652 13.3946 16.0196 13.2071 16.2071C13.0196 16.3946 12.7652 16.5 12.5 16.5C12.2348 16.5 11.9804 16.3946 11.7929 16.2071C11.6054 16.0196 11.5 15.7652 11.5 15.5V6H10V15.5C10 16.163 10.2634 16.7989 10.7322 17.2678C11.2011 17.7366 11.837 18 12.5 18C13.163 18 13.7989 17.7366 14.2678 17.2678C14.7366 16.7989 15 16.163 15 15.5V5C15 3.93913 14.5786 2.92172 13.8284 2.17157C13.0783 1.42143 12.0609 1 11 1C9.93913 1 8.92172 1.42143 8.17157 2.17157C7.42143 2.92172 7 3.93913 7 5V17.5C7 18.9587 7.57946 20.3576 8.61091 21.3891C9.64236 22.4205 11.0413 23 12.5 23C13.9587 23 15.3576 22.4205 16.3891 21.3891C17.4205 20.3576 18 18.9587 18 17.5V6H16.5Z" fill="#212427"/>
                        </svg>
                    </span>
                </div>            
            </div>
            <div className="w-full mb-8 relative z-[5]">
                <label className="font-manrope-m text-base text-blue-1 capitalize">Likes</label>
                <div className=""> 
                <Combobox
                    value={selectedLikes}
                    onChange={setSelectedLikes}
                    name="likes"
                    multiple
                >
                    <div className="flex bg-white-smoke w-full px-2 min-h-[2.7rem]  rounded-lg items-center">
                            <div className=" my-1">
                                <ul className="flex flex-wrap">
                                {selectedLikes.map((like) => (
                                    <li key={like.id} className="pl-3 my-1 h-6 font-manrope-r text-sm bg-YInMn_Blue_20 text-YInMn_Blue flex items-center mr-2 rounded-full">
                                        {like.name}
                                        <span 
                                            className="cursor-pointer border mx-1 ml-2 p-1 bg-YInMn_Blue_60 border border-YInMn_Blue_10 rounded-full my-auto" 
                                            onClick={(e) => handleRemoveLikes(like)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                            </svg>
                                        </span>
                                    </li>
                                ))}
                                <li className="flex items-center">
                                    <div className="grow w-max">
                                        <Combobox.Input
                                            onChange={(event) => setQuery(event.target.value)}
                                            className="focus:ring-offset-0 focus:ring-0 w-full bg-white-smoke border-none p-0"
                                            value={query}
                                            onKeyUp={(e) => e.key === 'Enter' && handleAddLikes(query)}
                                            autocomplete="off"
                                        />
                                    </div>
                                    </li>
                                </ul>
                            </div>
                        
                    </div>
                    {
                        filteredLikes.length > 0 &&    
                            <Combobox.Options
                                className="absolute font-manrope-m text-sm max-h-60 py-2  w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                            {filteredLikes.map((Likes) => (
                                <Combobox.Option 
                                    key={Likes.id} 
                                    value={Likes}
                                    className={({ active }) =>
                                    "relative cursor-default select-none py-2 pl-3  pr-4 font-manrope-m text-base "+ ( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                                    }
                                >
                                {Likes.name}
                                </Combobox.Option>
                            ))}
                            </Combobox.Options>
                    }

                </Combobox>
                </div>
            </div>
            <div className="w-full mb-8">
                <label className="font-manrope-m text-base text-blue-1 capitalize">Dislikes</label>
                <div className=""> 
                    <Combobox
                        value={selectedDislikes}
                        onChange={setSelectedDislikes}
                        name="Dislikes"
                        multiple
                    >
                        <div className="flex bg-white-smoke w-full px-2 min-h-[2.7rem]  rounded-lg items-center">
                                <div className=" my-1">
                                    <ul className="flex flex-wrap">
                                    {selectedDislikes.map((dislike) => (
                                        <li key={dislike.id} className="pl-3 my-1 h-6 font-manrope-r text-sm bg-YInMn_Blue_20 text-YInMn_Blue flex items-center mr-2 rounded-full">
                                            {dislike.name}
                                            <span 
                                                className="cursor-pointer border mx-1 ml-2 p-1 bg-YInMn_Blue_60 border border-YInMn_Blue_10 rounded-full my-auto" 
                                                onClick={(e) => handleRemoveDislikes(dislike)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                                </svg>
                                            </span>
                                        </li>
                                    ))}
                                    <li className="flex items-center">
                                        <div className="grow w-max">
                                            <Combobox.Input
                                                onChange={(event) => setQueryDislikes(event.target.value)}
                                                className="focus:ring-offset-0 focus:ring-0 w-full bg-white-smoke border-none p-0"
                                                value={queryDislikes}
                                                onKeyUp={(e) => e.key === 'Enter' && handleAddDislikes(queryDislikes)}
                                                autocomplete="off"
                                            />
                                        </div>
                                        </li>
                                    </ul>
                                </div>
                        
                        </div>
                        {
                            filteredDislikes.length > 0 &&    
                                <Combobox.Options
                                    className="absolute font-manrope-m text-sm max-h-60 py-2  w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                {filteredDislikes.map((dislike) => (
                                    <Combobox.Option 
                                        key={dislike.id} 
                                        value={dislike}
                                        className={({ active }) =>
                                        "relative cursor-default select-none py-2 pl-3  pr-4 font-manrope-m text-base "+ ( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                                        }
                                    >
                                    {dislike.name}
                                    </Combobox.Option>
                                ))}
                                </Combobox.Options>
                        }

                    </Combobox>
                </div>
            </div>
            <div className="w-full mb-8">
                <label className="font-manrope-m text-base text-blue-1 capitalize">Description</label>
                <textarea rows="5" type="text" placeholder="Type something about your dog...." className="w-full font-manrope-m text-sm placeholder-black-2-20 bg-white-smoke border border-gray-3 rounded-lg px-4 py-4 mt-2 focus:ring-offset-0 focus:ring-0" />
            </div>
            <div className="w-full mb-8 relative">
                <label className="font-manrope-m text-base text-blue-1 capitalize">Photo Upload</label>
                <input type="text" placeholder="Add file URL" className="h-11 w-full bg-white-smoke placeholder-black-2-20  border border-gray-3 rounded-lg px-3 pr-20 mt-2 focus:ring-offset-0 focus:ring-0" />
                <input type="file" ref={fileRef} className="hidden"/> 
                <div className="absolute top-8 right-0 h-11 flex items-center px-4">
                    <button className="text-primary-60 font-manrope-m text-base flex items-center p-0 align-middle" onClick={fileUpload}>Upload</button>
                </div>           
            </div>
        </>
    )
}
export default PetRegistrationDetails;