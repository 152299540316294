
import {useEffect,useState,useRef} from 'react';
import { Listbox,Combobox } from '@headlessui/react';

const messages = [
    {   id: 1,
        'name' : 'Cameron Williamson',
        'message' : 'Hello, do you have any offers or discounts for startups ?',
        'image' : 'https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
    },
    {
        id: 2,
        'name' : 'Ronald Richards',
        'message' : "Hello, I can't seem to find my projects. I upgraded to pro last week and now all of..",
        'image' : 'https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1167&q=80'
    },
    {
        id: 3,
        'name' : 'Esther Howard',
        'message' : 'Hello, do you have any offers or discounts for startups ?',
        'image' : 'https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80'
    },
    {
        id: 4,
        'name' : 'Brooklyn Simmons',
        'message' : 'Hello, do you have any offers or discounts for startups ?',
        'image' : 'https://images.unsplash.com/photo-1546961329-78bef0414d7c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
    },{
        id: 5,
        'name' : 'Ronald Richards',
        'message' : "Hello, I can't seem to find my projects. I upgraded to pro last week and now all of..",
        'image' : 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80'
    },
    {
        id: 6,
        'name' : 'Esther Howard',
        'message' : 'Hello, do you have any offers or discounts for startups ?',
        'image' : 'https://images.unsplash.com/photo-1569913486515-b74bf7751574?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=689&q=80'
    },
    {
        id: 7,
        'name' : 'Brooklyn Simmons',
        'message' : 'Hello, do you have any offers or discounts for startups ?',
        'image' : 'https://images.unsplash.com/photo-1623330188314-8f4645626731?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=717&q=80'
    },
    {
        id: 8,
        'name' : 'Brooklyn Simmons',
        'message' : 'Hello, do you have any offers or discounts for startups ?',
        'image' : 'https://images.unsplash.com/photo-1624298357597-fd92dfbec01d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
    },{
        id: 9,
        'name' : 'Ronald Richards',
        'message' : "Hello, I can't seem to find my projects. I upgraded to pro last week and now all of..",
        'image' : 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
    },
    {
        id: 10,
        'name' : 'Esther Howard',
        'message' : 'Hello, do you have any offers or discounts for startups ?',
        'image' : 'https://images.unsplash.com/photo-1654110455429-cf322b40a906?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80'
    },
    {
        id: 11,
        'name' : 'Brooklyn Simmons',
        'message' : 'Hello, do you have any offers or discounts for startups ?',
        'image' : 'https://images.unsplash.com/photo-1575859431774-2e57ed632664?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
    },
]
const sortTypes = [
        { id: 1, type: "A - Z" },
        { id: 2, type: "Z - A" },
        { id: 3, type: "newest" },
        { id: 4, type: "oldest" }
]

function Inbox(props){
    const [conversationVisible,setConversationVisible] = useState(false);
    const [bookingVisible,setBookingVisible] = useState(false);
    const [inboxVisible,setInboxVisible] = useState(true);
    const [viewBooking,setViewBooking] = useState(false);
    const [viewIndex,setViewIndex] = useState(true);
    const [viewConversation,setViewConversation] = useState(false);
    const [sortType,setSortType] = useState(sortTypes[2]);
    const [bookingbackButton,setBookingbackButton] = useState(false);
    const [conversationbackButton,setConversationbackButton] = useState(false);
    const [screen,setScreen] = useState('');
    const [messager,setMessager] = useState(messages[0]);
    const inboxRef =useRef([]);
    
    useEffect(() => {
        const handleWindowResize = () => {
            console.log(window.innerWidth);
            if(window.innerWidth < 640){
                setConversationVisible(false);
                setBookingVisible(false);
                setInboxVisible(viewConversation || viewBooking ? false : true);
                setBookingbackButton(true);
                setConversationbackButton(true);
                setScreen('xs');
            }else if(window.innerWidth > 640 && window.innerWidth < 768){
                setConversationVisible(false);
                setBookingVisible(false);
                setInboxVisible(viewConversation || viewBooking ? false : true);
                setBookingbackButton(true);
                setConversationbackButton(true);
                setScreen('sm');
            }else if(window.innerWidth > 768 && window.innerWidth < 1024){
                setConversationVisible(viewBooking ? false : true);
                setBookingVisible(viewBooking ? true : false);
                setInboxVisible(true);
                setBookingbackButton(true);
                setConversationbackButton(false);
                setScreen('md');
            }else if(window.innerWidth > 1024 && window.innerWidth < 1280){
                setConversationVisible(viewBooking ? false : true);
                setBookingVisible(viewBooking ? true : false);
                setBookingbackButton(true);
                setConversationbackButton(false);
                setInboxVisible(true);
                setScreen('lg');
            }
            else if(window.innerWidth > 1024){
                setConversationVisible(true);
                setBookingVisible(true);
                setBookingbackButton(false);
                setConversationbackButton(false);
                setInboxVisible(true);
                setScreen('above');
            }
        };

        inboxRef.current[9].scrollIntoView();

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const handleBooking = () =>{
        setViewBooking(true);
        setConversationVisible(false);
        setViewConversation(false);
        setBookingVisible(true);
    }
    const  handleCloseBooking = () =>{
        setViewBooking(false);
        setConversationVisible(true);
        setBookingVisible(false);
        setViewConversation(true);
    }

    const  handleConversation = (data) =>{
        setMessager(data);
        setViewConversation(true);
        setViewBooking(false);
    }
    const  handleCloseConversation = () =>{
        setViewBooking(false);
        setConversationVisible(false);
        setBookingVisible(false);
        setViewConversation(false);
        setInboxVisible(true);
    }
  
    const SortInbox = () =>{
        return(
            <Listbox
                value={sortType}
                onChange={setSortType}
                name="sortType"
            >
                <Listbox.Button
                    className="relative z-[10] h-11 w-full rounded-lg px-3 text-start capitalize font-manrope-m text-sm text-blue-1"                                            
                >
                    <span className="block truncate">{sortType.type}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 fill-blue-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>
                    </span>
                </Listbox.Button>
                <Listbox.Options
                    className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                {sortTypes.map((sort) => (
                    <Listbox.Option 
                        key={sort.id} 
                        value={sort}
                        className={({ active }) =>
                        "relative cursor-default select-none py-2 pl-3  pr-4 "+ ( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                        }
                    >
                    {sort.type}
                    </Listbox.Option>
                ))}
                </Listbox.Options>
            </Listbox>
        );
    }

    const MessageIndex = () =>{
        return(
            <div className="border border-smokey-violet rounded-xl bg-gray-2 h-[47.5rem] overflow-auto">
                <div className="flex justify-between px-3 py-1 items-center border-b border-shuttle-gray-20">
                    <div>
                        <h2 className="font-manrope-m text-[15px] text-blue-1">Index (16)</h2>
                    </div>
                    <div className="relative w-24">
                        <SortInbox/>
                    </div>
                </div>
                <div className="md:max-lg:pt-0 pt-5 h-[44rem] overflow-auto">
                    <ul role="list" className=" divide-y divide-shuttle-gray-20">
                        {messages.map((data,i) => 
                            <li key={i} ref={el => inboxRef.current[i] = el} className={"flex py-4 px-3 items-center cursor-pointer  " + (data.id == messager.id ? ' bg-yellow-1 text-white' : '')} onClick={(e) => handleConversation(data)}>
                                <div>
                                    <figure className="w-14 h-14">
                                        <img className="h-full w-full object-cover rounded-full object-center" src={data.image} alt="" />
                                    </figure>
                                </div>
                                <div className="ml-3 w-10/12">
                                    <h4 className="font-manrope-m text-base text-blue-1 mb-1">{data.name}</h4>
                                    <p className="font-manrope-r text-sm text-blue-1 line-clamp-2">{data.message}</p>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        )
    }

    const Conversation = () =>{
        return(
            <div className="border border-smokey-violet rounded-xl bg-gray-2 relative h-[47.5rem]">
                <div className="p-3 border-b  border-shuttle-gray-20 flex justify-between">
                    <div className="flex items-center">
                        {
                           conversationbackButton &&
                            <div className="flex items-center mr-2">
                                <button className="text-blue-1" onClick={handleCloseConversation}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                    </svg>
                                </button>
                            </div>
                        }
                        <h2 className="font-manrope-m text-[15px] text-blue-1">{ !bookingVisible && screen != "md" ? messager.name : "Conversation"}</h2>
                    </div>
                    { !bookingVisible &&
                        <div>
                            <button className="font-manrope-m text-sm text-hanblue capitalize underline" onClick={handleBooking}>view booking</button>
                        </div>
                    }
                </div>
                <div className="pt-7 h-[35rem] overflow-auto">
                    <ul role="list">
                    <li>
                            <div className="flex px-3 mb-3">
                                <div className="w-16 flex items-end">
                                    <img className="h-11 w-11 rounded-full" src="https://images.unsplash.com/photo-1634926878768-2a5b3c42f139?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=756&q=80" alt="" /> 
                                </div>
                                <div className="w-full border border-smokey-violet rounded-lg border">
                                    <div className="w-fullg w-full relative p-2 border-b border-smokey-violet px-3">
                                        <h5 className="font-manrope-m text-sm text-blue-1 mb-1">Pet Sitting - 2 nights</h5>
                                        <span className="font-manrope-r text-xs text-blue-1 block mb-1">13 Feb 2023 - 15 Feb 2023</span>
                                        <span className="font-manrope-r text-xs text-blue-1 block">Ref: 2085319</span>
                                        <div className="absolute right-2 bottom-1">
                                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_1099_544)">
                                                <path d="M13.125 15.2996L19.725 8.69957C19.825 8.59957 19.896 8.49124 19.938 8.37457C19.98 8.25791 20.0007 8.13291 20 7.99957C20 7.86624 19.979 7.74124 19.937 7.62457C19.895 7.50791 19.8243 7.39957 19.725 7.29957L13.125 0.699572C12.9417 0.516239 12.7123 0.420572 12.437 0.412572C12.1617 0.404572 11.9243 0.500239 11.725 0.699572C11.525 0.882905 11.4207 1.11224 11.412 1.38757C11.4033 1.66291 11.4993 1.90024 11.7 2.09957L16.6 6.99957H5.42498C5.14165 6.99957 4.90399 7.09557 4.71198 7.28757C4.51998 7.47957 4.42432 7.71691 4.42498 7.99957C4.42498 8.28291 4.52065 8.52057 4.71198 8.71257C4.90332 8.90457 5.14098 9.00024 5.42498 8.99957H16.6L11.7 13.8996C11.5167 14.0829 11.4207 14.3162 11.412 14.5996C11.4033 14.8829 11.4993 15.1162 11.7 15.2996C11.8833 15.4996 12.1167 15.5996 12.4 15.5996C12.6833 15.5996 12.925 15.4996 13.125 15.2996Z" fill="#386CB9"/>
                                                </g>
                                                <defs>
                                                <filter id="filter0_d_1099_544" x="0.424927" y="0.412109" width="23.5751" height="23.1875" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                <feOffset dy="4"/>
                                                <feGaussianBlur stdDeviation="2"/>
                                                <feComposite in2="hardAlpha" operator="out"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1099_544"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1099_544" result="shape"/>
                                                </filter>
                                                </defs>
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="px-3 py-4">
                                        <p className="font-manrope-r text-sm text-black-1 leading-relaxed line-clamp-3">Adam will review your enquiry.<br></br> Ensure your Pet Profile is up to date to help them get to know your furry friend.</p>
                                    </div>
                                    <button className="w-full bg-YInMn_Blue text-white h-11 rounded-b-lg font-manrope-sb text-sm">
                                        Review Pet Profile
                                    </button>
                                </div>
                            
                            </div>
                        </li>
                        <li>
                            <div className="flex px-3 mb-3">
                                <div className="w-16 flex items-end">
                                    <img className="h-11 w-11 rounded-full" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" /> 
                                </div>
                                <div className="w-full border border-smokey-violet rounded-lg">
                                    <div className="w-fullg w-full relative p-2 border-b border-smokey-violet px-3">
                                        <h5 className="font-manrope-r text-sm text-blue-1 mb-1">Pet Sitting - 2 nights</h5>
                                        <span className="font-manrope-r text-xs text-blue-1 block mb-1">13 Feb 2023 - 15 Feb 2023</span>
                                        <span className="font-manrope-r text-xs text-blue-1 block">Ref: 2085319</span>
                                        <div className="absolute right-2 bottom-1">
                                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_1099_544)">
                                                <path d="M13.125 15.2996L19.725 8.69957C19.825 8.59957 19.896 8.49124 19.938 8.37457C19.98 8.25791 20.0007 8.13291 20 7.99957C20 7.86624 19.979 7.74124 19.937 7.62457C19.895 7.50791 19.8243 7.39957 19.725 7.29957L13.125 0.699572C12.9417 0.516239 12.7123 0.420572 12.437 0.412572C12.1617 0.404572 11.9243 0.500239 11.725 0.699572C11.525 0.882905 11.4207 1.11224 11.412 1.38757C11.4033 1.66291 11.4993 1.90024 11.7 2.09957L16.6 6.99957H5.42498C5.14165 6.99957 4.90399 7.09557 4.71198 7.28757C4.51998 7.47957 4.42432 7.71691 4.42498 7.99957C4.42498 8.28291 4.52065 8.52057 4.71198 8.71257C4.90332 8.90457 5.14098 9.00024 5.42498 8.99957H16.6L11.7 13.8996C11.5167 14.0829 11.4207 14.3162 11.412 14.5996C11.4033 14.8829 11.4993 15.1162 11.7 15.2996C11.8833 15.4996 12.1167 15.5996 12.4 15.5996C12.6833 15.5996 12.925 15.4996 13.125 15.2996Z" fill="#386CB9"/>
                                                </g>
                                                <defs>
                                                <filter id="filter0_d_1099_544" x="0.424927" y="0.412109" width="23.5751" height="23.1875" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                <feOffset dy="4"/>
                                                <feGaussianBlur stdDeviation="2"/>
                                                <feComposite in2="hardAlpha" operator="out"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1099_544"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1099_544" result="shape"/>
                                                </filter>
                                                </defs>
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="px-3 py-4">
                                        <p className="font-manrope-r text-sm text-black-1 leading-relaxed line-clamp-3">Adam will review your enquiry.<br></br> Ensure your Pet Profile is up to date to help them get to know your furry friend.</p>
                                    </div>
                                    <button className="w-full bg-YInMn_Blue text-white h-11 rounded-b-lg font-manrope-sb text-sm">
                                        Review Pet Profile
                                    </button>
                                </div>
                            
                            </div>
                        </li>
                        <li>
                            <div className="flex px-3 mb-3">
                                <div className="w-16 flex items-end">
                                    <img className="h-11 w-11 rounded-full" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" /> 
                                </div>
                                <div className="w-full border border-smokey-violet rounded-lg">
                                    <div className="w-fullg w-full relative p-2 border-b border-smokey-violet px-3">
                                        <h5 className="font-manrope-r text-sm text-blue-1 mb-1">Pet Sitting - 2 nights</h5>
                                        <span className="font-manrope-r text-xs text-blue-1 block mb-1">13 Feb 2023 - 15 Feb 2023</span>
                                        <span className="font-manrope-r text-xs text-blue-1 block">Ref: 2085319</span>
                                        <div className="absolute right-2 bottom-1">
                                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_1099_544)">
                                                <path d="M13.125 15.2996L19.725 8.69957C19.825 8.59957 19.896 8.49124 19.938 8.37457C19.98 8.25791 20.0007 8.13291 20 7.99957C20 7.86624 19.979 7.74124 19.937 7.62457C19.895 7.50791 19.8243 7.39957 19.725 7.29957L13.125 0.699572C12.9417 0.516239 12.7123 0.420572 12.437 0.412572C12.1617 0.404572 11.9243 0.500239 11.725 0.699572C11.525 0.882905 11.4207 1.11224 11.412 1.38757C11.4033 1.66291 11.4993 1.90024 11.7 2.09957L16.6 6.99957H5.42498C5.14165 6.99957 4.90399 7.09557 4.71198 7.28757C4.51998 7.47957 4.42432 7.71691 4.42498 7.99957C4.42498 8.28291 4.52065 8.52057 4.71198 8.71257C4.90332 8.90457 5.14098 9.00024 5.42498 8.99957H16.6L11.7 13.8996C11.5167 14.0829 11.4207 14.3162 11.412 14.5996C11.4033 14.8829 11.4993 15.1162 11.7 15.2996C11.8833 15.4996 12.1167 15.5996 12.4 15.5996C12.6833 15.5996 12.925 15.4996 13.125 15.2996Z" fill="#386CB9"/>
                                                </g>
                                                <defs>
                                                <filter id="filter0_d_1099_544" x="0.424927" y="0.412109" width="23.5751" height="23.1875" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                <feOffset dy="4"/>
                                                <feGaussianBlur stdDeviation="2"/>
                                                <feComposite in2="hardAlpha" operator="out"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1099_544"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1099_544" result="shape"/>
                                                </filter>
                                                </defs>
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="px-3 py-4">
                                        <p className="font-manrope-r text-sm text-black-1 leading-relaxed line-clamp-3">Adam will review your enquiry.<br></br> Ensure your Pet Profile is up to date to help them get to know your furry friend.</p>
                                    </div>
                                    <button className="w-full bg-YInMn_Blue text-white h-11 rounded-b-lg font-manrope-sb text-sm">
                                        Review Pet Profile
                                    </button>
                                </div>
                            
                            </div>
                        </li>
                        <li>
                            <div className="text-center py-3 mb-2">
                                <span className="font-manrope-r text-xs text-blue-1-50">11/02/2022 08:11 AM</span>
                            </div>
                        </li>
                        <li>
                            <div className="flex px-3 mb-3">
                                <div className="w-2/12 flex items-end">
                                    <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" /> 
                                </div>
                                <div className="w-10/12 bg-white-smoke rounded-r-lg rounded-tl-lg px-2 py-4">
                                    <p className="font-manrope-r text-sm text-black-1 leading-relaxed line-clamp-3">Hello, I can't seem to find my projects. I upgraded to pro last week and now all of them are missing.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="flex px-3 mb-8">
                                <div className="w-10/12 bg-primary rounded-l-lg rounded-tr-lg px-2 py-4 ml-auto">
                                    <p className="font-manrope-r text-sm text-black-1 leading-relaxed text-white">Hello, I can't seem to find my projects. I upgraded to pro last week and now all of them are missing.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="w-full px-3">
                    <div className="w-full bg-white-smoke p-4 my-4  rounded-lg">
                        <div className="w-full">
                                <input type="text" placeholder="Write a message or attach a file"  className="h-9 w-full bg-white-smoke font-manrope-r text-sm p-0 border-none focus:ring-offset-0 focus:ring-0"/>
                        </div>  
                        <div className="flex w-full justify-between mt-2">
                            <div className="flex justify-items-center items-center">
                                <span className="mr-3">   
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_1099_538)">
                                        <path d="M16.9286 0.224609H1.92859C1.53076 0.224609 1.14923 0.377737 0.867929 0.650305C0.586624 0.922873 0.428589 1.29256 0.428589 1.67803V16.2122C0.428589 16.5977 0.586624 16.9673 0.867929 17.2399C1.14923 17.5125 1.53076 17.6656 1.92859 17.6656H16.9286C17.3264 17.6656 17.7079 17.5125 17.9892 17.2399C18.2706 16.9673 18.4286 16.5977 18.4286 16.2122V1.67803C18.4286 1.29256 18.2706 0.922873 17.9892 0.650305C17.7079 0.377737 17.3264 0.224609 16.9286 0.224609ZM16.9286 16.2122H1.92859V11.8519L5.67859 8.2184L9.87109 12.2807C10.1521 12.5514 10.5323 12.7033 10.9286 12.7033C11.3249 12.7033 11.705 12.5514 11.9861 12.2807L13.1786 11.1252L16.9286 14.7588V16.2122ZM16.9286 12.7022L14.2361 10.0933C13.955 9.82261 13.5749 9.67066 13.1786 9.67066C12.7823 9.67066 12.4021 9.82261 12.1211 10.0933L10.9286 11.2488L6.73609 7.18647C6.45505 6.91577 6.07487 6.76383 5.67859 6.76383C5.28231 6.76383 4.90213 6.91577 4.62109 7.18647L1.92859 9.79535V1.67803H16.9286V12.7022Z" fill="#404347" fill-opacity="0.5" shape-rendering="crispEdges"/>
                                        </g>
                                        <defs>
                                        <filter id="filter0_d_1099_538" x="-3.57141" y="0.224609" width="26" height="25.4414" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="4"/>
                                        <feGaussianBlur stdDeviation="2"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1099_538"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1099_538" result="shape"/>
                                        </filter>
                                        </defs>
                                    </svg>
                                </span>
                                <span className="mr-3">  
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1099_530)">
                                        <path d="M16.8441 8.30659L9.62343 15.5273C8.73884 16.4119 7.53908 16.9089 6.28807 16.9089C5.03707 16.9089 3.83731 16.4119 2.95272 15.5273C2.06813 14.6427 1.57117 13.4429 1.57117 12.1919C1.57117 10.9409 2.06813 9.74118 2.95272 8.85659L10.1734 1.63588C10.7632 1.04615 11.563 0.714844 12.397 0.714844C13.231 0.714844 14.0308 1.04615 14.6206 1.63588C15.2103 2.2256 15.5416 3.02545 15.5416 3.85945C15.5416 4.69345 15.2103 5.49329 14.6206 6.08302L7.392 13.3037C7.246 13.4497 7.07267 13.5656 6.88191 13.6446C6.69115 13.7236 6.48669 13.7643 6.28022 13.7643C6.07374 13.7643 5.86928 13.7236 5.67852 13.6446C5.48776 13.5656 5.31443 13.4497 5.16843 13.3037C5.02243 13.1577 4.90661 12.9844 4.8276 12.7936C4.74858 12.6029 4.70791 12.3984 4.70791 12.1919C4.70791 11.9855 4.74858 11.781 4.8276 11.5903C4.90661 11.3995 5.02243 11.2262 5.16843 11.0802L11.8391 4.41731" stroke="#404347" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_1099_530">
                                        <rect width="17.1429" height="18" fill="white" transform="translate(0.857178)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span className="mr-3">                                                
                                    <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1099_532)">
                                        <path d="M9 16.875C6.91142 16.875 4.90838 16.0453 3.43153 14.5685C1.95468 13.0916 1.125 11.0886 1.125 9C1.125 6.91142 1.95468 4.90838 3.43153 3.43153C4.90838 1.95468 6.91142 1.125 9 1.125C11.0886 1.125 13.0916 1.95468 14.5685 3.43153C16.0453 4.90838 16.875 6.91142 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z" fill="#404347" fill-opacity="0.5"/>
                                        <path d="M4.82066 10.7629C4.94986 10.6883 5.10339 10.6681 5.24749 10.7067C5.39158 10.7453 5.51444 10.8396 5.58904 10.9688C5.93454 11.5676 6.43172 12.0649 7.03053 12.4105C7.62934 12.7562 8.30864 12.9379 9.00004 12.9375C9.69143 12.9379 10.3707 12.7562 10.9695 12.4105C11.5684 12.0649 12.0655 11.5676 12.411 10.9688C12.4477 10.9043 12.4968 10.8477 12.5554 10.8022C12.614 10.7568 12.6811 10.7234 12.7527 10.7039C12.8242 10.6845 12.899 10.6794 12.9725 10.689C13.0461 10.6985 13.117 10.7225 13.1813 10.7596C13.2455 10.7967 13.3018 10.8462 13.3469 10.9051C13.3919 10.964 13.4249 11.0313 13.4438 11.103C13.4628 11.1747 13.4674 11.2495 13.4573 11.323C13.4473 11.3965 13.4228 11.4673 13.3853 11.5312C12.9411 12.3011 12.3019 12.9404 11.532 13.3848C10.7622 13.8292 9.88891 14.0629 9.00004 14.0625C8.11117 14.0629 7.23788 13.8292 6.46805 13.3848C5.69822 12.9404 5.05903 12.3011 4.61479 11.5312C4.5402 11.4021 4.51999 11.2485 4.55859 11.1044C4.5972 10.9603 4.69147 10.8375 4.82066 10.7629ZM7.87504 7.3125C7.87504 8.244 7.37104 9 6.75004 9C6.12904 9 5.62504 8.244 5.62504 7.3125C5.62504 6.381 6.12904 5.625 6.75004 5.625C7.37104 5.625 7.87504 6.381 7.87504 7.3125ZM12.375 7.3125C12.375 8.244 11.871 9 11.25 9C10.629 9 10.125 8.244 10.125 7.3125C10.125 6.381 10.629 5.625 11.25 5.625C11.871 5.625 12.375 6.381 12.375 7.3125Z" fill="#404347" fill-opacity="0.5"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_1099_532">
                                        <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </div>
                            <div className="bg-orange-10 w-11 h-11 flex justify-items-center items-center rounded-full">
                                <span className="mx-auto"> 
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.7825 3.21843C20.6813 3.11768 20.5534 3.04793 20.4139 3.01737C20.2744 2.98681 20.1291 2.99672 19.995 3.04593L3.495 9.04593C3.3527 9.0999 3.23019 9.19589 3.14374 9.32114C3.05729 9.44639 3.01099 9.59498 3.01099 9.74718C3.01099 9.89937 3.05729 10.048 3.14374 10.1732C3.23019 10.2985 3.3527 10.3945 3.495 10.4484L10.695 13.3284L13.575 20.5284C13.6291 20.6645 13.7219 20.7817 13.8418 20.8657C13.9618 20.9496 14.1036 20.9967 14.25 21.0009C14.4016 20.9978 14.5486 20.9489 14.6718 20.8605C14.795 20.7721 14.8885 20.6485 14.94 20.5059L20.94 4.00593C20.9911 3.87323 21.0034 3.72875 20.9755 3.58932C20.9477 3.44989 20.8807 3.32126 20.7825 3.21843ZM14.25 18.1509L12.1575 12.9009L15.75 9.30843L14.6925 8.25093L11.07 11.8734L5.85 9.75093L18.9975 5.00343L14.25 18.1509Z" fill="#F1A33A"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const BookingsDetails = () =>{
        return(
            <div className="col-span-1 md:col-span-3 lg:col-span-3 xl:col-span-1">
                <div className="border border-smokey-violet rounded-xl bg-gray-2 h-[47.5rem]">
                    <div className="p-3 border-b  border-shuttle-gray-20 flex items-center">
                        {
                           bookingbackButton &&
                            <div className="flex items-center mr-2">
                                <button className="text-blue-1" onClick={handleCloseBooking}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                    </svg>
                                </button>
                            </div>
                        }
                        <div>
                            <h2 className="font-manrope-m text-[15px] text-blue-1">Booking</h2>
                        </div>
                    </div>
                    <div >
                        <ul role="list" className=" divide-y divide-shuttle-gray-20">
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="py-3">
                                    <p className="font-manrope-m text-xs text-gray-1 mb-1">One-off</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1">Pet Sitting</p>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="py-3">
                                    <p className="font-manrope-m text-xs text-gray-1 mb-1">Date</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1">Feb 13 - 15</p>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="py-3">
                                    <p className="font-manrope-m text-xs text-gray-1 mb-1">Status</p>
                                    <p className="font-manrope-sb text-sm text-red-1 bg-red-1-10 px-2 rounded-full">Customer Cancelled</p>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="py-3">
                                    <p className="font-manrope-m text-xs text-gray-1 mb-1">Where</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1">Acton, ACT <span className="text-xs">(3.24km away)</span></p>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0 relative">
                                <div className="absolute top-3 right-3">
                                    <a href="#" className="text-hanblue font-manrope-m text-xs underline">View Photos</a>
                                </div>
                                <div className="py-3">
                                    <span className="font-manrope-m text-xs text-gray-1 mb-1">With</span>
                                    <div className="flex mt-2">
                                        <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" />
                                        <div className="ml-3">
                                            <p className="font-manrope-sb text-[15px] text-blue-1">Max</p>
                                            <p className="font-manrope-r text-sm text-blue-1-60">Labrador Retriever</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0 relative">
                                <div className="absolute top-3 right-3">
                                    <a href="#" className="text-hanblue font-manrope-m text-xs underline">View Photos</a>
                                </div>
                                <div className="py-3">
                                    <span className="font-manrope-m text-xs text-gray-1 mb-1 capitalize">{ props.userType == "owner" ? "sitter" : "owner" }</span>
                                    <div className="flex mt-2">
                                        <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" />
                                        <div className="ml-3">
                                            <p className="font-manrope-sb text-[15px] text-blue-1">Cameron Williamson</p>
                                            <p className="font-manrope-r text-sm text-blue-1-60">Canberra, ACT</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="flex  px-3 first:pt-0 last:pb-0">
                                <div className="py-3">
                                    <p className="font-manrope-m text-xs text-gray-1 mb-1">Payment & Cancellation Policy</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1">Flexible Policy</p>
                                </div>
                            </li>
                            <li className="flex px-3 first:pt-0 last:pb-0">
                                <div className="py-3">
                                    <p className="font-manrope-m text-xs text-gray-1 mb-1">Booking Number</p>
                                    <p className="font-manrope-sb text-[15px] text-blue-1">2085319</p>
                                </div>
                            </li>
                            <li className="flex mt-2 first:pt-0 last:pb-0">
                                <div className="bg-primary-40  py-6 relative rounded-b-lg mt-auto mb-0 w-full">
                                    <div className="px-3">
                                        <h5 className="font-manrope-m text-sm text-blue-1 mb-1">Estimated Total</h5>
                                        <a href="#" className="text-blue-1 underline flex items-center text-hanblue text-sm">
                                            View Details
                                            <span className="ml-1 mt-1">
                                                <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.32807 7.91289L4.48745 4.76289C4.52495 4.72539 4.55157 4.68477 4.56732 4.64102C4.58307 4.59727 4.59082 4.55039 4.59057 4.50039C4.59057 4.45039 4.58282 4.40352 4.56732 4.35977C4.55182 4.31602 4.5252 4.27539 4.48745 4.23789L1.32807 1.07852C1.24057 0.991016 1.1312 0.947266 0.999946 0.947266C0.868695 0.947266 0.756196 0.994141 0.662446 1.08789C0.568696 1.18164 0.521821 1.29102 0.521821 1.41602C0.521821 1.54102 0.568696 1.65039 0.662446 1.74414L3.4187 4.50039L0.662446 7.25664C0.574945 7.34414 0.531196 7.45202 0.531196 7.58027C0.531196 7.70852 0.578071 7.81939 0.671821 7.91289C0.765571 8.00664 0.874945 8.05352 0.999946 8.05352C1.12495 8.05352 1.23432 8.00664 1.32807 7.91289Z" fill="#386CB9"/>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="absolute top-0 right-10 h-full flex items-center">
                                        <h3 className="text-2xl text-blue-1 font-manrope-b">$98.1</h3>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="w-full lg:w-9/12 mx-auto sm:w-11/12 px-2 md:px-0 max-md:mt-2 md:mt-10">
            <div className="max-md:mt-0 mt-10"> 
                <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-3 gap-4">
                    {   
                        inboxVisible && 
                        <div className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-1">
                            <MessageIndex/>
                        </div>
                    }
                    {  (conversationVisible || viewConversation) &&
                       <div className="col-span-1 md:col-span-3 lg:col-span-3 xl:col-span-1">
                            <Conversation/> 
                        </div>     
                    }
                    { (viewBooking || bookingVisible ) && <BookingsDetails/> }
                </div>
            </div>
        </div>
    )
}
export default Inbox;