import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';


function ListBox(props){

    let value = props.value;
    let setValue = props.setValue;
    let header = props.header;
    let style = props.style;
    let name = props.name;
    let transparent = props.transparent;

    console.log(header);
    return(
        <Listbox 
            value={value} 
            onChange={setValue}
            name ={name}
        >
            <div className={"relative p-0 "+style}>
                <Listbox.Button className={"relative flex items-center w-full cursor-default py-2 pl-3 pr-8  md:pr-10 text-left focus:outline-none font-manrope-m text-base  " + ( name == "callerId" ? "h-7 border-r-2 border-white bg-white-smoke" : transparent ? "bg-white bg-opacity-0 " : "bg-white-smoke h-11 rounded-lg border border-gray-3")}>
                    <span>{header}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2  flex item-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 stroke-blue-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-3 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {props.children}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}
export default ListBox;