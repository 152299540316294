import { useState } from 'react';
import { format, set } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { Switch } from '@headlessui/react';

import {DaysList} from '../../Data/Data';

function Calendar(){

    const [selected, setSelected] = useState([]);
    const [awayEnabled, setAwayEnabled] = useState(false);
    const [repeatEnabled, setRepeatEnabled] = useState(false);


    const ToggleSwitch = (props) => {
        let status  = props.status;
        let setStatus = props.setStatus;
        let styles = props.styles;
        return(
            <Switch
                checked={status}
                onChange={setStatus}
                className={ (status ? "bg-green-30" : "bg-dark-gray") +
                " relative inline-flex items-center h-[18px] w-[38px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={ ( status ? 'translate-x-4 bg-green' : '-translate-x-1 bg-white') +
                    " pointer-events-none inline-block h-[25px] w-[25px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out"}
                />
            </Switch>
        );
    }

    return(
        <div className="w-9/12 mx-auto ">
            <div className="mt-10">
                <div className="flex gap-6">
                    <div className="w-7/12 border border-shuttle-gray-20 rounded-xl">
                        
                    </div>
                    <div className="w-5/12">
                        <div className="border border-shuttle-gray-20 rounded-xl">
                            <div className="border-b border-blue-1-10 p-6 px-7">
                                <h3 className="text-blue-1 text-[15px] font-manrope-m">Calendar Settings</h3>
                            </div>
                            <div className="p-6 px-7 pb-10">
                                <div className="mb-5">
                                    <h5 className="text-blue-1 text-sm font-manrope-m mb-2">Operating Days</h5>
                                    <p className="font-manrope-r text-sm text-gray-1 mb-6">Choose which days you want to be open for business and save changes.</p>
                                    <div>
                                        <div className="grid grid-cols-2 gap-4">
                                            {
                                                DaysList.map((day,index) =>(
                                                <div className="flex items-center space-x-3" key={index}>
                                                    <input type="checkbox" className="w-5 h-5 rounded-md" />
                                                    <label className="text-gray-1 font-manrope-m text-[15px] capitalize">{day.name}</label>
                                                </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-10">
                                    <h5 className="text-blue-1 text-sm font-manrope-m mb-2">Taking a Break?</h5>
                                    <p className="font-manrope-r text-sm text-gray-1 mb-6">If you are unable to respond to messages for a while, update to "away" and save so you don't disappoint potential clients.</p>
                                    <div>
                                        <div className="flex items-center space-x-3">
                                            <ToggleSwitch status={awayEnabled} setStatus={setAwayEnabled} />
                                            <span className="text-blue-1 text-[15px] font-manrope-m">Away</span>
                                        </div>
                                    </div>       
                                </div>
                                <div>
                                    <h5 className="text-blue-1 text-sm font-manrope-m mb-2">Taking a Break?</h5>
                                    <p className="font-manrope-r text-sm text-gray-1 mb-6">If you've got enough repeat customers select "Repeat Only” and Save.</p>
                                    <div>
                                        <div className="flex items-center space-x-3">
                                            <ToggleSwitch status={repeatEnabled} setStatus={setRepeatEnabled} />
                                            <span className="text-blue-1 text-[15px] font-manrope-m">Repeat Only</span>
                                        </div>
                                    </div>       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Calendar;