import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import Home2 from './Pages/Home2';
import Home from './Pages/Home';
import Providers from './Pages/Providers';
import ProviderHome from './Pages/ProviderHome';
import Search from './Pages/Search';
import ProvidersDashboard from './Pages/ProvidersDashboard';
import About from './Pages/About';
import Events from './Pages/Events';
import ContactUs from './Pages/ContactUs';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import PaymentDetails from './Pages/SignUp/Owner/PaymentDetails';
import PetRegistration from './Pages/SignUp/Owner/PetRegistration';
import AdditionalDetails from './Pages/SignUp/AdditionalDetails';
import Success from './Pages/SignUp/Sucess';
import ProviderAdditionalDetails from './Pages/SignUp/Provider/ProviderAdditionalDetails';
import ForgotPassword from './Pages/SignUp/ForgotPassword';
import Booking from './Pages/Booking/Booking';
import BookingSuccess from './Pages/Booking/BookingSuccess';
import DogProfileSuccess from './Pages/DogProfile/DogProfileSuccess';
import DogProfileDetails from './Pages/DogProfile/DogProfileDetails';
import DogProfileMoreDetails from './Pages/DogProfile/DogProfileMoreDetails';
import UserDashboard from './Pages/UserDashboard';
import reportWebVitals from './reportWebVitals';
import PetSitting from './Pages/PetSitting';
import PetHosting from './Pages/PetHosting';
import DayCare from './Pages/DayCare';
import DayVisits from './Pages/DayVisits';
import DogWalking from './Pages/DogWalking';


const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/providers",
    element: <Providers />,
  },
  {
    path: "/providerhome",
    element: <ProviderHome />,
  },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/providers-dashboard",
    element: <ProvidersDashboard />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/payment-details",
    element: <PaymentDetails />,
  },
  {
    path: "/pet-details",
    element: <PetRegistration />,
  },
  {
    path : "/additional-details",
    element : <AdditionalDetails/>,
  },
  {
    path : "/success",
    element : <Success/>
  },
  {
    path :"/provider-additional-details",
    element : <ProviderAdditionalDetails/>
  },
  {
    path : "/forgot-password",
    element : <ForgotPassword/>
  },
  {
    path : "/booking",
    element : <Booking/>
  },
  {
    path : "/booking-sucess",
    element : <BookingSuccess/>
  },
  {
    path : "/dog-profile-success",
    element : <DogProfileSuccess/>
  },
  {
    path : "/dog-profile-details",
    element : <DogProfileDetails/>
  },
  {
    path : "/dog-profile-more-details",
    element : <DogProfileMoreDetails/>
  },
  {
    path : "/user-dashboard",
    element : <UserDashboard/>
  },
  {
    path : "/pet-sitting",
    element : <PetSitting/>
  },
  {
    path : "/pet-hosting",
    element : <PetHosting/>
  },
  {
    path : "/day-care",
    element : <DayCare/>
  },
  {
    path : "/day-visits",
    element : <DayVisits/>
  },
  {
    path : "/dog-walking",
    element : <DogWalking/>
  }

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
