
function Payments(){
    return(
        <div className="border border-shuttle-gray-20 rounded-lg">
            <ul role="list" className=" divide-y divide-shuttle-gray-20">
                <li className="flex px-2 md:px-4">
                    <div className="ml-3 py-5">
                        <p className="font-manrope-sb text-base text-blue-1 mb-1">Payment Information</p>
                        <p className="font-manrope-r text-sm text-gray-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </li>
                <li className="flex  px-10 py-8 ">
                   
                </li>
            </ul>
        </div>
    )
}
export default Payments;