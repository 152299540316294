import { useState } from 'react';

import { Tab } from '@headlessui/react'
import { Fragment } from 'react'


import Header  from "../Components/Header";
import Footer from "../Components/Footer";
import Inbox from '../Components/Inbox';
import Bookings from '../Components/Bookings';
import PersonalDetails from '../Components/PersonalDetails';
import Calendar from '../Components/ProviderDashboard/Calendar'



const tabs = [
    {   
        icon : <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.04797 3H11.048V12H4.04797V3ZM15.048 3H22.048V8H15.048V3ZM15.048 12H22.048V21H15.048V12ZM4.04797 16H11.048V21H4.04797V16Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>, 
        name : 'Dashboard'
    },
    {
        icon : 
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.04799 14H15.048V12H7.04799V14ZM7.04799 11H19.048V9H7.04799V11ZM7.04799 8H19.048V6H7.04799V8ZM3.04799 22V4C3.04799 3.45 3.24399 2.979 3.63599 2.587C4.02732 2.19567 4.49799 2 5.04799 2H21.048C21.598 2 22.069 2.19567 22.461 2.587C22.8523 2.979 23.048 3.45 23.048 4V16C23.048 16.55 22.8523 17.021 22.461 17.413C22.069 17.8043 21.598 18 21.048 18H7.04799L3.04799 22ZM5.04799 17.175L6.22299 16H21.048V4H5.04799V17.175Z" />
            </svg>
        , 
        name : 'Inbox'
    },
    {
        icon : 
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.873 8.575C18.7396 8.575 18.6146 8.554 18.498 8.512C18.3813 8.47067 18.273 8.4 18.173 8.3L16.748 6.875C16.548 6.675 16.448 6.43733 16.448 6.162C16.448 5.88733 16.548 5.65833 16.748 5.475C16.9313 5.275 17.1646 5.175 17.448 5.175C17.7313 5.175 17.9646 5.26667 18.148 5.45L18.873 6.175L21.698 3.325C21.898 3.125 22.1313 3.025 22.398 3.025C22.6646 3.025 22.9063 3.125 23.123 3.325C23.3063 3.525 23.398 3.76667 23.398 4.05C23.398 4.33333 23.3063 4.56667 23.123 4.75L19.573 8.3C19.473 8.4 19.3646 8.47067 19.248 8.512C19.1313 8.554 19.0063 8.575 18.873 8.575ZM7.44797 20.4C7.11464 20.5333 6.79797 20.504 6.49797 20.312C6.19797 20.1207 6.04797 19.8417 6.04797 19.475V5C6.04797 4.45 6.24397 3.979 6.63597 3.587C7.02731 3.19567 7.49797 3 8.04797 3H14.048V5H8.04797V17.95L13.048 15.8L18.048 17.95V11H20.048V19.475C20.048 19.8417 19.898 20.1207 19.598 20.312C19.298 20.504 18.9813 20.5333 18.648 20.4L13.048 18L7.44797 20.4ZM8.04797 5H14.048H13.048H8.04797Z" />
            </svg>
        ,                 
        name : 'Bookings'
    },
    {
        icon : 
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.048 17H18.048C18.248 17 18.398 16.9083 18.498 16.725C18.598 16.5417 18.5813 16.3667 18.448 16.2L15.698 12.525C15.598 12.3917 15.4647 12.325 15.298 12.325C15.1313 12.325 14.998 12.3917 14.898 12.525L12.298 16L10.448 13.525C10.348 13.3917 10.2147 13.325 10.048 13.325C9.88134 13.325 9.748 13.3917 9.648 13.525L7.648 16.2C7.51467 16.3667 7.498 16.5417 7.598 16.725C7.698 16.9083 7.848 17 8.048 17ZM6.048 21C5.498 21 5.027 20.8043 4.635 20.413C4.24367 20.021 4.048 19.55 4.048 19V5C4.048 4.45 4.24367 3.979 4.635 3.587C5.027 3.19567 5.498 3 6.048 3H20.048C20.598 3 21.069 3.19567 21.461 3.587C21.8523 3.979 22.048 4.45 22.048 5V19C22.048 19.55 21.8523 20.021 21.461 20.413C21.069 20.8043 20.598 21 20.048 21H6.048ZM6.048 19H20.048V5H6.048V19Z" />
            </svg>
        ,
        name : 'Photos'
    },
    {
        icon : 
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.048 2C11.7348 2 10.4344 2.25866 9.22114 2.7612C8.00788 3.26375 6.90549 4.00035 5.97691 4.92893C4.10154 6.8043 3.04797 9.34784 3.04797 12C3.04797 14.6522 4.10154 17.1957 5.97691 19.0711C6.90549 19.9997 8.00788 20.7362 9.22114 21.2388C10.4344 21.7413 11.7348 22 13.048 22C15.7001 22 18.2437 20.9464 20.119 19.0711C21.9944 17.1957 23.048 14.6522 23.048 12C23.048 10.6868 22.7893 9.38642 22.2868 8.17317C21.7842 6.95991 21.0476 5.85752 20.119 4.92893C19.1905 4.00035 18.0881 3.26375 16.8748 2.7612C15.6616 2.25866 14.3612 2 13.048 2ZM8.11797 18.28C8.54797 17.38 11.168 16.5 13.048 16.5C14.928 16.5 17.548 17.38 17.978 18.28C16.5774 19.396 14.8388 20.0026 13.048 20C11.188 20 9.47797 19.36 8.11797 18.28ZM19.408 16.83C17.978 15.09 14.508 14.5 13.048 14.5C11.588 14.5 8.11797 15.09 6.68797 16.83C5.62416 15.4446 5.04764 13.7467 5.04797 12C5.04797 7.59 8.63797 4 13.048 4C17.458 4 21.048 7.59 21.048 12C21.048 13.82 20.428 15.5 19.408 16.83ZM13.048 6C11.108 6 9.54797 7.56 9.54797 9.5C9.54797 11.44 11.108 13 13.048 13C14.988 13 16.548 11.44 16.548 9.5C16.548 7.56 14.988 6 13.048 6ZM13.048 11C12.6501 11 12.2686 10.842 11.9873 10.5607C11.706 10.2794 11.548 9.89782 11.548 9.5C11.548 9.10218 11.706 8.72064 11.9873 8.43934C12.2686 8.15804 12.6501 8 13.048 8C13.4458 8 13.8273 8.15804 14.1086 8.43934C14.3899 8.72064 14.548 9.10218 14.548 9.5C14.548 9.89782 14.3899 10.2794 14.1086 10.5607C13.8273 10.842 13.4458 11 13.048 11Z"/>
            </svg>
        ,
        name : 'Accounts'
    },
    {
        icon : 
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.04798 14C7.76464 14 7.52698 13.904 7.33498 13.712C7.14298 13.52 7.04731 13.2827 7.04798 13C7.04798 12.7167 7.14398 12.479 7.33598 12.287C7.52798 12.095 7.76531 11.9993 8.04798 12C8.33131 12 8.56898 12.096 8.76098 12.288C8.95298 12.48 9.04864 12.7173 9.04798 13C9.04798 13.2833 8.95198 13.521 8.75998 13.713C8.56798 13.905 8.33064 14.0007 8.04798 14ZM12.048 14C11.7646 14 11.527 13.904 11.335 13.712C11.143 13.52 11.0473 13.2827 11.048 13C11.048 12.7167 11.144 12.479 11.336 12.287C11.528 12.095 11.7653 11.9993 12.048 12C12.3313 12 12.569 12.096 12.761 12.288C12.953 12.48 13.0486 12.7173 13.048 13C13.048 13.2833 12.952 13.521 12.76 13.713C12.568 13.905 12.3306 14.0007 12.048 14ZM16.048 14C15.7646 14 15.527 13.904 15.335 13.712C15.143 13.52 15.0473 13.2827 15.048 13C15.048 12.7167 15.144 12.479 15.336 12.287C15.528 12.095 15.7653 11.9993 16.048 12C16.3313 12 16.569 12.096 16.761 12.288C16.953 12.48 17.0486 12.7173 17.048 13C17.048 13.2833 16.952 13.521 16.76 13.713C16.568 13.905 16.3306 14.0007 16.048 14ZM5.04798 22C4.49798 22 4.02698 21.804 3.63498 21.412C3.24298 21.02 3.04731 20.5493 3.04798 20V6C3.04798 5.45 3.24398 4.979 3.63598 4.587C4.02798 4.195 4.49864 3.99933 5.04798 4H6.04798V2.975C6.04798 2.69167 6.14398 2.45833 6.33598 2.275C6.52798 2.09167 6.76531 2 7.04798 2C7.33131 2 7.56898 2.096 7.76098 2.288C7.95298 2.48 8.04864 2.71733 8.04798 3V4H16.048V2.975C16.048 2.69167 16.144 2.45833 16.336 2.275C16.528 2.09167 16.7653 2 17.048 2C17.3313 2 17.569 2.096 17.761 2.288C17.953 2.48 18.0486 2.71733 18.048 3V4H19.048C19.598 4 20.069 4.196 20.461 4.588C20.853 4.98 21.0486 5.45067 21.048 6V20C21.048 20.55 20.852 21.021 20.46 21.413C20.068 21.805 19.5973 22.0007 19.048 22H5.04798ZM5.04798 20H19.048V10H5.04798V20ZM5.04798 8H19.048V6H5.04798V8Z" />
            </svg>
        ,
        name : 'Calendar'
    },
]

const RepeatDate = [
{
    header : "Repeat clients",
    percentage : "00",
    status : true,
    subtext : "O Repeat clients Local average",
    text : "Percentage of people who have booked with you more than once"
},
{
    header : "New repeat clients",
    percentage : "00",
    status : false,
    subtext : "O Local average",
    text : "Number of people who have made a second booking with you in the past 90 days"
},
{
    header : "Total clients",
    percentage : "01",
    status : false,
    subtext : "4 Local average",
    text : "Number of clients who have booked at least once"
},

]

const BookingDate = [
{
    header : "Completed bookings",
    percentage : "00",
    status : false,
    subtext : "2 Local average",
    text : "Total number of completed bookings in the past 90 days"
},
{
    header : "New repeat clients",
    percentage : "00",
    status : true,
    subtext : "O Local average",
    text : "Percentage Of enquiries converted into bookings in the past 90 days"
},
{
    header : "Response time",
    percentage : "01",
    status : false,
    subtext : "17 hrs Local average",
    text : "Average time to respond to enquiries in the past 90 days"
},
{
    header : "Bookings with reviews",
    percentage : "01",
    status : true,
    subtext : "O Reviews 28% Local average",
    text : "Average time to respond to enquiries in the past 90 days"
},

]

const providerPets = [
{
    'name' : 'Oliver',
    'type' : 'Affengriffon',
    'age' : '2.5 years',
    'image' : 'https://images.unsplash.com/photo-1587300003388-59208cc962cb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'

},
{
    'name' : 'Morgana',
    'type' : 'Bichonaranian',
    'age' : '2.5 years',
    'image' : 'https://images.unsplash.com/photo-1530281700549-e82e7bf110d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80'
}
]

const messages = [
{
    'name' : 'Cameron Williamson',
    'message' : 'Hello, do you have any offers or discounts for startups ?',
    'image' : 'https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
},
{
    'name' : 'Ronald Richards',
    'message' : "Hello, I can't seem to find my projects. I upgraded to pro last week and now all of..",
    'image' : 'https://images.unsplash.com/photo-1543610892-0b1f7e6d8ac1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
},
{
    'name' : 'Esther Howard',
    'message' : 'Hello, do you have any offers or discounts for startups ?',
    'image' : 'https://images.unsplash.com/photo-1569913486515-b74bf7751574?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=689&q=80'
},
{
    'name' : 'Brooklyn Simmons',
    'message' : 'Hello, do you have any offers or discounts for startups ?',
    'image' : 'https://images.unsplash.com/photo-1601455763557-db1bea8a9a5a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1212&q=80'
},
]


function ProvidersDashboard(){

    const [selectedIndex, setSelectedIndex] = useState(0);
    

    const Dashboard = () =>{
        return(
            <div className="w-full lg:w-9/12 mx-auto sm:w-11/12 px-2 md:px-0 mt-10">
                <div className="bg-primary-40 rounded-xl relative mt-10 z-10 max-sm:h-[22em] max-md:h-[18em]">
                    <div className=" p-8 max-md:w-full max-lg:w-full w-full z-[50]">
                        <h2 className="text-2xl font-manrope-b text-blue-1 mb-7 max-md:pr-28">Hi 👋 Good Evening Gethin !</h2>
                        <div className="flex space-x-3 align-items-center items-center">
                            <img className=" h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" />
                            <button className="w-max bg-primary text-white rounded-full max-md:px-3 px-7 font-manrope-r text-sm h-10">View Profile</button>
                            <button className="max-md:hidden border-2 border-primary text-primary rounded-full max-md:px-3 px-6 font-manrope-r h-10 text-sm">Edit Profile</button>
                            <button className="rounded-full bg-light-blue px-3 h-10">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1099_888)">
                                    <path d="M13.0007 18.0697C10.184 18.0697 7.93066 15.8164 7.93066 12.9997C7.93066 10.183 10.184 7.92969 13.0007 7.92969C15.8173 7.92969 18.0707 10.183 18.0707 12.9997C18.0707 15.8164 15.8173 18.0697 13.0007 18.0697ZM13.0007 9.05635C10.8037 9.05635 9.05733 10.8027 9.05733 12.9997C9.05733 15.1967 10.8037 16.943 13.0007 16.943C15.1977 16.943 16.944 15.1967 16.944 12.9997C16.944 10.8027 15.1977 9.05635 13.0007 9.05635Z" fill="#4EABD6"/>
                                    <path d="M14.5212 23.7035H11.4792L10.6342 21.1122C10.0709 20.9432 9.50754 20.7179 9.00054 20.4362L6.52188 21.6755L4.38121 19.5349L5.62054 17.0562C5.33888 16.5492 5.11354 15.9859 4.94454 15.4225L2.29688 14.5212V11.4792L4.88821 10.6342C5.05721 10.0709 5.28254 9.50754 5.56421 9.00054L4.32488 6.52187L6.46554 4.38121L8.94421 5.62054C9.45121 5.33887 10.0145 5.11354 10.5779 4.94454L11.4792 2.29688H14.5212L15.3662 4.88821C15.9295 5.05721 16.4929 5.28254 16.9999 5.56421L19.4785 4.32488L21.6192 6.46554L20.3799 8.94421C20.6615 9.45121 20.8869 10.0145 21.0559 10.5779L23.6472 11.4229V14.4649L21.0559 15.3099C20.8869 15.8732 20.6615 16.4365 20.3799 16.9435L21.6192 19.4222L19.4785 21.5629L16.9999 20.3235C16.4929 20.6052 15.9295 20.8305 15.3662 20.9995L14.5212 23.7035ZM12.2679 22.5769H13.7325L14.5212 20.1545L14.8029 20.0982C15.4789 19.9292 16.0985 19.6475 16.7182 19.3095L16.9999 19.1405L19.2532 20.2672L20.2672 19.2532L19.1405 16.9999L19.3095 16.7182C19.6475 16.1549 19.9292 15.4789 20.0982 14.8029L20.1545 14.5212L22.5769 13.7325V12.2679L20.1545 11.4792L20.0982 11.1975C19.9292 10.5215 19.6475 9.90188 19.3095 9.28221L19.1405 9.00054L20.2672 6.74721L19.2532 5.73321L16.9999 6.85988L16.7182 6.69088C16.0985 6.35288 15.4789 6.07121 14.8029 5.90221L14.5212 5.84588L13.7325 3.42354H12.2679L11.4792 5.84588L11.1975 5.90221C10.5215 6.07121 9.90188 6.35288 9.28221 6.69088L9.00054 6.85988L6.74721 5.73321L5.73321 6.74721L6.85988 9.00054L6.69088 9.28221C6.35288 9.84554 6.07121 10.5215 5.90221 11.1975L5.84588 11.4792L3.42354 12.2679V13.7325L5.84588 14.5212L5.90221 14.8029C6.07121 15.4789 6.35288 16.0985 6.69088 16.7182L6.85988 16.9999L5.73321 19.2532L6.74721 20.2672L9.00054 19.1405L9.28221 19.3095C9.90188 19.6475 10.5215 19.9292 11.1975 20.0982L11.4792 20.1545L12.2679 22.5769Z" fill="#4EABD6"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_1099_888">
                                    <rect width="26" height="26" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </button>
                            <button>
                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_1110_4141)">
                                    <path d="M18 20C17.1667 20 16.4583 19.7083 15.875 19.125C15.2917 18.5417 15 17.8333 15 17C15 16.8833 15.0083 16.7623 15.025 16.637C15.0417 16.5117 15.0667 16.3993 15.1 16.3L8.05 12.2C7.76667 12.45 7.45 12.646 7.1 12.788C6.75 12.93 6.38333 13.0007 6 13C5.16667 13 4.45833 12.7083 3.875 12.125C3.29167 11.5417 3 10.8333 3 10C3 9.16667 3.29167 8.45833 3.875 7.875C4.45833 7.29167 5.16667 7 6 7C6.38333 7 6.75 7.071 7.1 7.213C7.45 7.355 7.76667 7.55067 8.05 7.8L15.1 3.7C15.0667 3.6 15.0417 3.48767 15.025 3.363C15.0083 3.23833 15 3.11733 15 3C15 2.16667 15.2917 1.45833 15.875 0.875C16.4583 0.291667 17.1667 0 18 0C18.8333 0 19.5417 0.291667 20.125 0.875C20.7083 1.45833 21 2.16667 21 3C21 3.83333 20.7083 4.54167 20.125 5.125C19.5417 5.70833 18.8333 6 18 6C17.6167 6 17.25 5.92933 16.9 5.788C16.55 5.64667 16.2333 5.45067 15.95 5.2L8.9 9.3C8.93333 9.4 8.95833 9.51267 8.975 9.638C8.99167 9.76333 9 9.884 9 10C9 10.1167 8.99167 10.2377 8.975 10.363C8.95833 10.4883 8.93333 10.6007 8.9 10.7L15.95 14.8C16.2333 14.55 16.55 14.3543 16.9 14.213C17.25 14.0717 17.6167 14.0007 18 14C18.8333 14 19.5417 14.2917 20.125 14.875C20.7083 15.4583 21 16.1667 21 17C21 17.8333 20.7083 18.5417 20.125 19.125C19.5417 19.7083 18.8333 20 18 20Z" fill="#4EABD6"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_1110_4141" x="-1" y="0" width="26" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="4"/>
                                    <feGaussianBlur stdDeviation="2"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1110_4141"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1110_4141" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 bottom-0 -z-[1] flex items-end ">
                        <figure className="w-full h-44 ">
                            <img src="icons/Frame.png" className="h-full w-full object-cover" al="" />
                        </figure>
                    </div>
                </div>
                <section>
                    <div className="w-full mt-8">
                        <div className="grid  max-md:grid-cols-1 max-md:space-y-7 grid-cols-3 gap-4 ">
                            <div>
                                <h3 className="font-manrope-m text-base ml-5 text-blue-1 mb-3">Repeat Performance</h3>
                                <div className="border border-smokey-violet rounded-xl p-4 pb-10 mb-3">
                                    <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">Total repeat business</h4>
                                    <div className="flex my-4 items-center">
                                        <div>
                                            <h2 className="font-manrope-sb text-4xl  text-blue-1">
                                                100 
                                                <span className="font-manrope-r text-4xl ml-1">%</span>
                                            </h2>
                                        </div>
                                        <div>
                                            <p className="font-manrope-r text-sm  text-blue-1 ml-5 mr-3">0 Repeat bookings 13% Local average</p>
                                        </div>
                                    </div>
                                    <p className="font-manrope-r text-sm  text-gray-1 leading-relaxed">Percentage of bookings from previous clients in the past 90 days</p>
                                    <div className="mt-3">
                                        <table className="w-full text-left table-auto ">
                                            <thead className="px-2">
                                                <tr className="font-manrope-l text-sm  text-blue-grafy border-b border-smokey-violet">
                                                    <th className="py-2">By service type</th>
                                                    <th className="py-2">You</th>
                                                    <th className="py-2">Local</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="font-manrope-r text-sm  text-gray-1">
                                                    <td className="py-2">Hosting</td>
                                                    <td className="py-2">00%</td>
                                                    <td className="py-2">00%</td>
                                                </tr>
                                                <tr className="font-manrope-r text-sm  text-gray-1">
                                                    <td className="py-2">Sitting</td>
                                                    <td className="py-2">00%</td>
                                                    <td className="py-2">06%</td>
                                                </tr>
                                                <tr className="font-manrope-r text-sm  text-gray-1">
                                                    <td className="py-2">Walking</td>
                                                    <td className="py-2">00%</td>
                                                    <td className="py-2">12%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="border border-smokey-violet rounded-xl">
                                    {RepeatDate.map( (data,i) => 
                                        <div className="border-b border-smokey-violet p-4 last:border-none pb-8">
                                            <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">{data.header}</h4>
                                            <div className="flex my-4 items-center">
                                                <div>
                                                    <h2 className="font-manrope-sb text-4xl  text-blue-1">
                                                        {data.percentage} 
                                                        {data.status && <span className="font-manrope-r text-4xl ml-1">%</span>}
                                                    </h2>
                                                </div>
                                                <div>
                                                    <p className="font-manrope-r text-sm  text-blue-1 ml-5 mr-3 leading-relaxed">{data.subtext}</p>
                                                </div>
                                            </div>
                                            <p className="font-manrope-r text-sm  text-gray-1 leading-relaxed">{data.text}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="block">
                                <h3 className="font-manrope-m text-base ml-5 text-blue-1 mb-3">Booking Performance</h3>
                                <div className="border border-smokey-violet rounded-xl mb-4 pb-5">
                                    {BookingDate.map( (data,i) => 
                                        <div className="border-b border-smokey-violet p-4 last:border-none pb-4">
                                            <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">{data.header}</h4>
                                            <div className="flex my-4 items-center">
                                                <div>
                                                    <h2 className="font-manrope-sb text-4xl  text-blue-1">
                                                        {data.percentage} 
                                                        {data.status && <span className="font-manrope-r text-4xl ml-1">%</span>}
                                                    </h2>
                                                </div>
                                                <div>
                                                    <p className="font-manrope-r text-sm  text-blue-1 ml-5 mr-3 leading-relaxed">{data.subtext}</p>
                                                </div>
                                            </div>
                                            <p className="font-manrope-r text-sm  text-gray-1 leading-relaxed">{data.text}</p>
                                        </div>
                                    )}
                                </div>
                                <div className="block mt-3 border  border-white">
                                    <h3 className="font-manrope-m text-base ml-5 text-blue-1 mb-3 mt-2">Get Approved As A Sitter</h3>
                                    <div className="border border-smokey-violet rounded-xl">
                                        <div className="border-b border-smokey-violet p-4 last:border-none pb-5">
                                            <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">Submit Your Training Certificate</h4>
                                            <p className="font-manrope-r text-sm  text-gray-1">Please upload your Training certificate.</p>
                                            <button className="bg-primary text-white rounded-full px-7 font-manrope-r text-sm h-10 mt-3">Upload Document</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="block mb-8 border  border-white">
                                    <h3 className="font-manrope-m text-base ml-5 text-blue-1 mb-3">Payment</h3>
                                    <div className="border border-smokey-violet rounded-xl bg-white-smoke py-2">
                                        <div className="p-5 pt-4 ">
                                            <div className="flex justify-between mb-3">
                                                <h4 className="font-manrope-m text-sm  text-gray-1">Balance</h4>
                                                <span>        
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.4967 14.166H11.1634V9.16602H9.4967V14.166ZM10.33 7.49935C10.5661 7.49935 10.7642 7.41935 10.9242 7.25935C11.0842 7.09935 11.1639 6.90157 11.1634 6.66602C11.1634 6.4299 11.0834 6.23185 10.9234 6.07185C10.7634 5.91185 10.5656 5.83213 10.33 5.83268C10.0939 5.83268 9.89587 5.91268 9.73587 6.07268C9.57587 6.23268 9.49615 6.43046 9.4967 6.66602C9.4967 6.90213 9.5767 7.10018 9.7367 7.26018C9.8967 7.42018 10.0945 7.4999 10.33 7.49935ZM10.33 18.3327C9.17726 18.3327 8.09393 18.1138 7.08004 17.676C6.06615 17.2382 5.1842 16.6446 4.4342 15.8952C3.6842 15.1452 3.09059 14.2632 2.65337 13.2493C2.21615 12.2355 1.99726 11.1521 1.9967 9.99935C1.9967 8.84657 2.21559 7.76324 2.65337 6.74935C3.09115 5.73546 3.68476 4.85352 4.4342 4.10352C5.1842 3.35352 6.06615 2.7599 7.08004 2.32268C8.09393 1.88546 9.17726 1.66657 10.33 1.66602C11.4828 1.66602 12.5661 1.8849 13.58 2.32268C14.5939 2.76046 15.4759 3.35407 16.2259 4.10352C16.9759 4.85352 17.5698 5.73546 18.0075 6.74935C18.4453 7.76324 18.6639 8.84657 18.6634 9.99935C18.6634 11.1521 18.4445 12.2355 18.0067 13.2493C17.5689 14.2632 16.9753 15.1452 16.2259 15.8952C15.4759 16.6452 14.5939 17.2391 13.58 17.6768C12.5661 18.1146 11.4828 18.3332 10.33 18.3327ZM10.33 16.666C12.1911 16.666 13.7675 16.0202 15.0592 14.7285C16.3509 13.4368 16.9967 11.8605 16.9967 9.99935C16.9967 8.13824 16.3509 6.56185 15.0592 5.27018C13.7675 3.97852 12.1911 3.33268 10.33 3.33268C8.46893 3.33268 6.89254 3.97852 5.60087 5.27018C4.3092 6.56185 3.66337 8.13824 3.66337 9.99935C3.66337 11.8605 4.3092 13.4368 5.60087 14.7285C6.89254 16.0202 8.46893 16.666 10.33 16.666Z" fill="#898E92"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <h2 className="font-manrope-eb text-3xl  text-blue-1 mb-3">$ 45.02</h2>
                                            <a  href="#" className="font-manrope-m text-base  text-blue-1 underline">Request Payment</a>
                                        </div>
                                    </div>
                                </div> 
                                <div className="block mb-8 border  border-white">
                                    <h3 className="font-manrope-m text-base ml-5 text-blue-1 mb-3 mt-2">Doggo Day Credits</h3>
                                    <div className="border border-smokey-violet rounded-xl bg-white-smoke py-2">
                                        <div className="p-5 pt-4 ">
                                            <div className="flex justify-between mb-3">
                                                <h4 className="font-manrope-m text-sm  text-gray-1">Discounts</h4>
                                                <span>        
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.4967 14.166H11.1634V9.16602H9.4967V14.166ZM10.33 7.49935C10.5661 7.49935 10.7642 7.41935 10.9242 7.25935C11.0842 7.09935 11.1639 6.90157 11.1634 6.66602C11.1634 6.4299 11.0834 6.23185 10.9234 6.07185C10.7634 5.91185 10.5656 5.83213 10.33 5.83268C10.0939 5.83268 9.89587 5.91268 9.73587 6.07268C9.57587 6.23268 9.49615 6.43046 9.4967 6.66602C9.4967 6.90213 9.5767 7.10018 9.7367 7.26018C9.8967 7.42018 10.0945 7.4999 10.33 7.49935ZM10.33 18.3327C9.17726 18.3327 8.09393 18.1138 7.08004 17.676C6.06615 17.2382 5.1842 16.6446 4.4342 15.8952C3.6842 15.1452 3.09059 14.2632 2.65337 13.2493C2.21615 12.2355 1.99726 11.1521 1.9967 9.99935C1.9967 8.84657 2.21559 7.76324 2.65337 6.74935C3.09115 5.73546 3.68476 4.85352 4.4342 4.10352C5.1842 3.35352 6.06615 2.7599 7.08004 2.32268C8.09393 1.88546 9.17726 1.66657 10.33 1.66602C11.4828 1.66602 12.5661 1.8849 13.58 2.32268C14.5939 2.76046 15.4759 3.35407 16.2259 4.10352C16.9759 4.85352 17.5698 5.73546 18.0075 6.74935C18.4453 7.76324 18.6639 8.84657 18.6634 9.99935C18.6634 11.1521 18.4445 12.2355 18.0067 13.2493C17.5689 14.2632 16.9753 15.1452 16.2259 15.8952C15.4759 16.6452 14.5939 17.2391 13.58 17.6768C12.5661 18.1146 11.4828 18.3332 10.33 18.3327ZM10.33 16.666C12.1911 16.666 13.7675 16.0202 15.0592 14.7285C16.3509 13.4368 16.9967 11.8605 16.9967 9.99935C16.9967 8.13824 16.3509 6.56185 15.0592 5.27018C13.7675 3.97852 12.1911 3.33268 10.33 3.33268C8.46893 3.33268 6.89254 3.97852 5.60087 5.27018C4.3092 6.56185 3.66337 8.13824 3.66337 9.99935C3.66337 11.8605 4.3092 13.4368 5.60087 14.7285C6.89254 16.0202 8.46893 16.666 10.33 16.666Z" fill="#898E92"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <h2 className="font-manrope-eb text-3xl  text-blue-1 mb-3">$ 0.00</h2>
                                            <a  href="#" className="font-manrope-m text-base  text-blue-1 underline">Redeem Credits</a>
                                        </div>
                                    </div>
                                </div> 
                                <div className="block mb-3 border  border-white">
                                    <h3 className="font-manrope-m text-base ml-5 text-blue-1 mb-3 mt-2">Get Approved As A Sitter</h3>
                                    <div className="border border-smokey-violet rounded-xl">
                                        <div className="border-b border-smokey-violet p-4 last:border-none pb-5">
                                            <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">Submit Your Proof of Identity</h4>
                                            <p className="font-manrope-r text-sm  text-gray-1 leading-relaxed">Upload a photo/scan of a valid form of
                                                identity. such as your divers license,
                                                photo card or passport.
                                            </p>
                                            <button className="bg-primary text-white rounded-full px-7 font-manrope-r text-sm h-10 mt-4 ">Upload Document</button>
                                        </div>
                                    </div>
                                </div>   
                                <div className="block border  border-white">
                                    <h3 className="font-manrope-m text-base ml-5 text-blue-1 mb-3 mt-2">Improve Your Profile</h3>
                                    <div className="border border-smokey-violet rounded-xl">
                                        <div className="border-b border-smokey-violet p-4 last:border-none pb-5">
                                            <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">Get a Police Check</h4>
                                            <p className="font-manrope-r text-sm  text-gray-1 leading-relaxed">Police checks let Owners know you are a
                                                trusted member of the community. Upload
                                                a valid (less than 12 months old) police
                                                check.
                                            </p>
                                            <button className="bg-primary text-white rounded-full px-7 font-manrope-r text-sm h-10 mt-4">Upload Document</button>
                                        </div>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="w-full">
                        <div className="columns-1 gap-4 max-xl:block max-xl:space-y-4 flex py-10">
                            <div className="max-xl:w-full w-1/2">
                                <div className="border border-smokey-violet relative rounded-xl max-xl:block flex content-center p-4">
                                    <div className="max-xl:pb-3  pb-5 max-xl:w-full w-8/12 pr-3">
                                        <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">Manage your Calendar</h4>
                                        <p className="font-manrope-r text-sm  text-gray-1 leading-relaxed">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
                                        </p>
                                    </div>
                                    <div className="max-xl:w-full w-4/12 flex items-center xl:absolute xl:right-0 xl:top-0 xl:inset-y-0">
                                        <button className="bg-primary text-white rounded-full px-5 font-manrope-r text-sm h-10 max-xl:mt-0 max-xl:mt-2">View Calendar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="max-xl:w-full w-1/2">
                                <div className="border border-smokey-violet relative rounded-xl max-xl:block flex content-center p-4">
                                    <div className="max-xl:pb-3  pb-5 max-xl:w-full w-8/12 pr-3">
                                        <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">Notifications</h4>
                                        <p className="font-manrope-r text-sm  text-gray-1 leading-relaxed">
                                            We send SMS messages for booking-related notifications. You can select the notifications you would like to receive.
                                        </p>
                                    </div>
                                    <div className="max-xl:w-full w-4/12 flex items-center xl:absolute xl:right-0 xl:top-0 xl:inset-y-0">
                                        <button className="bg-primary text-white rounded-full px-5 font-manrope-r text-sm h-10 max-xl:mt-0 max-xl:mt-2">Update Settings</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="w-full">
                        <div className="max-lg:block max-lg:space-y-7 flex gap-4">
                            <div className="max-lg:w-full w-1/2">
                                <div className="block">
                                    <h3 className="font-manrope-m text-base ml-5 text-blue-1 mb-3 mt-2">Tell Us About Your Pets</h3>
                                    <div className="border border-smokey-violet rounded-xl">
                                        <div className="border-b border-smokey-violet p-4  pb-5">
                                            <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">Pets</h4>
                                            <p className="font-manrope-r text-sm  text-gray-1 leading-relaxed">Let us know more about your pets by adding them one by one. You can update your pet details at any time.</p>
                                        </div>
                                        <div className="w-full max-lg:px-4 p-7">
                                            <button className="w-full rounded-lg text flex justify-center items-center font-manrope-m text-base h-16 text-blue-1 border-dashed border-2 border-shuttle-gray-20 bg-shuttle-gray-20">
                                                <span>
                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.917 20.5827V14.0827H5.41699V11.916H11.917V5.41602H14.0837V11.916H20.5837V14.0827H14.0837V20.5827H11.917Z" fill="#061257"/>
                                                    </svg>
                                                </span>
                                                <span>Add More</span>
                                            </button>
                                            <div className="mt-9 space-y-4">
                                                { providerPets.map((data,i) => 
                                                <div className="relative flex border-dashed border-2 border-shuttle-gray-20 bg-shuttle-gray-2 px-4 py-2 rounded-lg">
                                                    <div className="text-center w-28 self-center">
                                                        <figure className='w-full h-32 m-auto '>
                                                            <img src={data.image} alt=""className='w-full h-full object-cover rounded-lg' />
                                                        </figure>
                                                    </div>
                                                    <div className="p-3 w-3/5">
                                                        <h2 className="text-lg font-manrope-sb text-blue-1 mb-2">{data.name}</h2>
                                                        <p  className="text-base font-manrope-r  text-blue-1">{data.type}</p>
                                                        <div className="mt-9">
                                                            <span className="text-base font-manrope-m  text-blue-1" >{data.age}</span>
                                                        </div>
                                                    </div>
                                                    <div className="absolute bottom-4 right-4 flex w-20 items-center justify-end">                                                    
                                                            <span>
                                                                <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.33333 15.8005C2.96667 15.8005 2.65267 15.6699 2.39133 15.4085C2.13044 15.1476 2 14.8339 2 14.4672V5.13385C2 4.76719 2.13044 4.45319 2.39133 4.19185C2.65267 3.93097 2.96667 3.80052 3.33333 3.80052H9.28333L7.95 5.13385H3.33333V14.4672H12.6667V9.83385L14 8.50052V14.4672C14 14.8339 13.8696 15.1476 13.6087 15.4085C13.3473 15.6699 13.0333 15.8005 12.6667 15.8005H3.33333ZM10.7833 4.18385L11.7333 5.11719L7.33333 9.51719V10.4672H8.26667L12.6833 6.05052L13.6333 6.98385L9.21667 11.4005C9.09444 11.5227 8.95289 11.6201 8.792 11.6925C8.63067 11.7645 8.46111 11.8005 8.28333 11.8005H6.66667C6.47778 11.8005 6.31956 11.7367 6.192 11.6092C6.064 11.4812 6 11.3227 6 11.1339V9.51719C6 9.33941 6.03333 9.16985 6.1 9.00852C6.16667 8.84763 6.26111 8.70608 6.38333 8.58385L10.7833 4.18385ZM13.6333 6.98385L10.7833 4.18385L12.45 2.51719C12.7167 2.25052 13.0362 2.11719 13.4087 2.11719C13.7807 2.11719 14.0944 2.25052 14.35 2.51719L15.2833 3.46719C15.5389 3.72274 15.6667 4.03385 15.6667 4.40052C15.6667 4.76719 15.5389 5.0783 15.2833 5.33385L13.6333 6.98385Z" fill="#061257"/>
                                                                </svg>
                                                            </span>
                                                            <span className="ml-2"> 
                                                                Edit
                                                            </span>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>          
                            </div>
                            <div className="max-lg:w-full w-1/2">
                                <div className="block">
                                    <h3 className="font-manrope-m text-base ml-5 text-blue-1 mb-3 mt-2">Your Favourite Sitters</h3>
                                    <div className="border border-smokey-violet rounded-xl">
                                        <div className="border-b border-smokey-violet p-4  pb-5">
                                            <h4 className="font-manrope-m text-sm  text-blue-1 mb-3">Saved Sitters</h4>
                                            <p className="font-manrope-r text-sm  text-gray-1 leading-relaxed ">Let us know more about your pets by adding them one by one. You can update your pet details at any time.</p>
                                        </div>
                                        <div className="w-full max-lg:px-4 p-7 space-y-4">
                                            <div className="relative flex  bg-white px-4 py-4 rounded-lg shadow-md shadow-YInMn_Blue_10 border border-primary-40">
                                                <div className="text-center w-24 self-center">
                                                    <figure className='w-full h-24 m-auto '>
                                                        <img src="https://images.unsplash.com/photo-1615903040611-e599dfaa6752?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt=""className='w-full h-full object-cover rounded-lg' />
                                                    </figure>
                                                </div>
                                                <div className="px-5 w-3/5">
                                                    <h2 className="text-lg font-manrope-sb text-blue-1 mb-2">Gethin Green</h2>
                                                    <div className="flex justify-start w-full items-center space-x-2 mb-4">
                                                        <svg width="15" height="18" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-gray-1">
                                                            <path d="M8.00008 9.9987C8.50425 9.9987 8.936 9.81903 9.29533 9.4597C9.65405 9.10098 9.83342 8.66953 9.83342 8.16536C9.83342 7.6612 9.65405 7.22945 9.29533 6.87011C8.936 6.51139 8.50425 6.33203 8.00008 6.33203C7.49591 6.33203 7.06447 6.51139 6.70575 6.87011C6.34642 7.22945 6.16675 7.6612 6.16675 8.16536C6.16675 8.66953 6.34642 9.10098 6.70575 9.4597C7.06447 9.81903 7.49591 9.9987 8.00008 9.9987ZM8.00008 16.7362C9.86397 15.0251 11.2466 13.4704 12.148 12.0722C13.0494 10.6746 13.5001 9.43342 13.5001 8.3487C13.5001 6.68342 12.969 5.31973 11.9069 4.25761C10.8454 3.19611 9.54314 2.66536 8.00008 2.66536C6.45703 2.66536 5.15444 3.19611 4.09233 4.25761C3.03083 5.31973 2.50008 6.68342 2.50008 8.3487C2.50008 9.43342 2.95078 10.6746 3.85216 12.0722C4.75355 13.4704 6.13619 15.0251 8.00008 16.7362ZM8.00008 19.1654C5.54036 17.0723 3.70336 15.1281 2.48908 13.3326C1.27419 11.5378 0.666748 9.87648 0.666748 8.3487C0.666748 6.05703 1.40405 4.23134 2.87866 2.87161C4.35266 1.51189 6.0598 0.832031 8.00008 0.832031C9.94036 0.832031 11.6475 1.51189 13.1215 2.87161C14.5961 4.23134 15.3334 6.05703 15.3334 8.3487C15.3334 9.87648 14.7263 11.5378 13.512 13.3326C12.2971 15.1281 10.4598 17.0723 8.00008 19.1654Z" />
                                                        </svg>
                                                        <h3 className="text-gray-1 font-manrope-md text-sm">Melbourne VIC, Australia</h3>
                                                    </div>
                                                    <div className="flex justify-start space-x-2 mr-5">
                                                        <span className="font-manrope-sb font-[800] text-sm text-orange">5.0</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-star-fill my-auto fill-orange" viewBox="0 0 16 16">
                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                                            </svg>
                                                        <span className="font-manrope-r self-end text-sm text-blue-1 ml-2">(20 Reviews)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>          
                            </div>
                        </div>                    
                    </div>
                </section>
            </div>
        )
    }
    const InboxMessage  = () =>{
        return( <Inbox userType="sitter"/>)
    }

    const Book = () =>{
        return (
            <Bookings userType="sitter"/>
        )
    }

    const Photos = () =>{
        return (
            <div className="w-full lg:w-9/12 mx-auto sm:w-11/12 px-2 md:px-0 mt-10">
                <div className="mt-10">
                    <div className="flex bg-white-smoke justify-between p-4 px-6 rounded-lg">
                        <div className="flex">
                            <div className="flex mt-2">
                                <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" />
                                <div className="ml-3">
                                    <p className="font-manrope-b text-lg text-blue-1">Cameron Williamson</p>
                                    <span className="text-gray-1">Pets : <span className="font-manrope-m text-base text-blue-1">Max</span></span>
                                </div>
                            </div>
                            <div className="mt-2 ml-16">
                                <span className="text-gray-1">Pet Hosting : <span className="font-manrope-m text-base text-blue-1">1 night</span></span>
                                <p className="font-manrope-sb text-sm text-blue-1 mt-2">23/08/2022 - 24/08/2022</p>
                            </div>
                        </div>
                        <div className="w-20 h-20 border border-blue-1-10 flex justify-center items-center rounded-lg">                           
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.75 23.75V16.25H6.25V13.75H13.75V6.25H16.25V13.75H23.75V16.25H16.25V23.75H13.75Z" fill="#061257" fill-opacity="0.5"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const Accounts = () =>{
        return (
            <div className="w-9/12 mx-auto ">
                <div className="mt-10">
                    <h2 className="font-manrope-m text-2xl text-blue-1 my-4 ml-4">Account</h2>
                    <div className="w-full flex">
                        <div className="w-3/12 px-3">
                            <ul>
                                <li><div className="py-2 font-manrope-sb text-base text-blue-1 px-3 rounded-full bg-YInMn_Blue_20 my-2">Personal Details</div></li>
                                <li><div className="py-2 font-manrope-sb text-base text-blue-1 px-3 hover:rounded-full hover:bg-YInMn_Blue_20 my-2">Services and Rates</div></li>
                                <li><div className="py-2 font-manrope-sb text-base text-blue-1 px-3 hover:rounded-full hover:bg-YInMn_Blue_20 my-2">Experience</div></li>
                                <li><div className="py-2 font-manrope-sb text-base text-blue-1 px-3 hover:rounded-full hover:bg-YInMn_Blue_20 my-2">Photo Gallery</div></li>
                                <li><div className="py-2 font-manrope-sb text-base text-blue-1 px-3 hover:rounded-full hover:bg-YInMn_Blue_20 my-2">Pets</div></li>
                                <li><div className="py-2 font-manrope-sb text-base text-blue-1 px-3 hover:rounded-full hover:bg-YInMn_Blue_20 my-2">Household</div></li>
                                <li><div className="py-2 font-manrope-sb text-base text-blue-1 px-3 hover:rounded-full hover:bg-YInMn_Blue_20 my-2">Payment Information</div></li>
                            </ul>
                        </div>
                        <div className="w-9/12">
                            <PersonalDetails/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const CalendarSettings = () =>{
        return (
            <Calendar/>
        )
    }

    return (
        <>
            <Header></Header>
            <div className="mt-28 grid justify-items-center">
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List className={'border-b border-shuttle-gray-20  max-md:fixed max-md:z-[50] max-md:shadow-lg max-md:left-0 max-md:bottom-0 max-md:bg-white  w-full md:w-10/12 lg:w-8/12 flex justify-center '}>
                    {tabs.map((el, i) =>
                    <Tab
                     className="w-2/12"
                     key={i}>
                        {({ selected }) => (
                            <div  className={"max-md:px-0 py-3  "+(selected ? ' text-hanblue border-b-4 border-hanblue active-nav' : 'nav-non bg-white text-gray-1')}>
                            <span className=" w-12 block flex justify-center mx-auto mb-2 fill-black ">{el.icon}</span>
                            <span className="max-md:hidden" >{el.name}</span>
                            </div>
                        )}
                    </Tab>
                    )}
                </Tab.List>
                <Tab.Panels className={' w-full'}>
                    <Tab.Panel >
                        <Dashboard/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <InboxMessage />
                    </Tab.Panel>
                    <Tab.Panel>
                        <Book/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <Photos/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <Accounts/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <CalendarSettings/>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
            </div>
            <Footer></Footer>
        </>
    )

}
export default ProvidersDashboard;