import React from 'react'
import {useRef, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper";


function Carousel(props) {
    const carousel= useRef();
    const swiperRef = useRef();
    

    useEffect(() => {
        //carousel.current.children[0].classList.add("w-full");
        carousel.current.children[0].style.width="100%";
    }, [])

    return(
        <>
        <Swiper
          slidesPerView={"auto"}
          dir={props.direction ? "rtl" : "ltr"}
          navigation={props.navigation}
          pagination={props.pagination}
          spaceBetween={props.space}
          loop={props.loop}
          modules={[Pagination,Navigation]}
          //initialSlide = {3}
          className={"mb-3 flex w-full "+( props.styles ? props.styles : "")}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          ref={carousel}
        >
            {props.children.map((el, i) =>
            <SwiperSlide className={props.width}  >
                {el}
            </SwiperSlide>
            )}
        </Swiper> 
        {
            props.card &&
            <div className="w-full flex max-md:justify-start justify-end gap-6 px-4 py-1">
                <button onClick={() => swiperRef.current.slidePrev()} className="w-12 h-12 rounded-full bg-YInMn_Blue_10 flex justify-center items-center">
                    <span>
                        <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-3 h-3">
                            <path d="M5 -2.18557e-07L2.18557e-07 5L5 10L5 -2.18557e-07Z" fill="#386CB9"/>
                        </svg>
                    </span>
                </button> 
                <button onClick={() => swiperRef.current.slideNext()} className="w-12 h-12 rounded-full bg-YInMn_Blue_10 flex justify-center items-center">
                    <span>
                        <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-3 h-3">
                        <path d="M0 -2.18557e-07L5 5L-4.37114e-07 10L0 -2.18557e-07Z" fill="#386CB9"/>
                        </svg>
                    </span>
                </button> 
            </div>
        }
                 
      </>
    )
  
}

export default Carousel