import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, forwardRef, useImperativeHandle } from 'react'


export default forwardRef (function MyInput(props, ref) {

    let [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            openModal() {
                setIsOpen(!isOpen);
            }
        };
    }, []);


    function closeModal() {
        setIsOpen(false);
    }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-manrope-sb leading-6 text-white bg-hanblue text-center p-3 relative"
                  >
                    Payment Details
                    <div className="absolute top-0 right-0 px-4 py-3" >
                        <span className="cursor-pointer" onClick={closeModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                  </Dialog.Title>
                  <div className="mt-2 border-b border-blue-1-10 py-5">
                    <h4 className="font-manrope-sb text-blue-1 text-base px-4 py-2">Pet Sitting</h4>
                    <div className="bg-YInMn_Blue_10 flex justify-between px-4 py-3">
                        <div>
                            <span className="font-manrope-r text-blue-1 text-base">$45 x 2 nights</span>
                        </div>
                        <div>
                            <span className="font-manrope-m text-blue-1 text-base" >$90.00</span>
                        </div>
                    </div>
                    <div className="text-end py-3 px-4">
                        <span className="font-manrope-sb text-blue-1 text-base">Sub-Total: $90.00</span>
                    </div>
                  </div>
                  <div className="mt-2 border-b border-blue-1-10 pt-3">
                    <h4 className="font-manrope-sb text-blue-1 text-base px-4 py-2">Estimated Total</h4>
                    <div className="bg-YInMn_Blue_10 flex justify-between px-4 py-3">
                        <div>
                            <span className="font-manrope-r text-blue-1 text-base">Services Total</span>
                        </div>
                        <div>
                            <span className="font-manrope-m text-blue-1 text-base" >$90.00</span>
                        </div>
                    </div>
                    <div className="bg-white flex justify-between px-4 py-3">
                        <div>
                            <span className="font-manrope-r text-blue-1 text-base">Booking Fee</span>
                        </div>
                        <div>
                            <span className="font-manrope-m text-blue-1 text-base" >$8.10</span>
                        </div>
                    </div>
                  </div>
                  <div className="bg-yellow flex justify-between px-4 py-3 mb-5">
                        <div>
                            <span className="font-manrope-sb text-blue-1 text-base">Estimated Total</span>
                        </div>
                        <div>
                            <span className="font-manrope-sb text-blue-1 text-base" >$98.10</span>
                        </div>
                    </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
})
