import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';

const dobMonthList = [
    {id: 1 , month :'January'},
    {id: 2 , month :'February'},
    {id: 3 , month :'March'},
    {id: 4 , month :'April'},
    {id: 5 , month :'May'},
    {id: 6 , month :'June'},
    {id: 7 , month :'July'},
    {id: 8 , month :'August'},
    {id: 9 , month :'September'},
    {id: 10 , month :'October'},
    {id: 11 , month :'November'},
    {id: 12 , month :'December'},
]

const countryList = [
    { id: 1 , country : 'Australia'}
]

function PersonalDetails(){
    const [selectedDobYear, setSelectedDobYear] = useState();
    const [selectedDobMonth, setSelectedDobMonth] = useState();
    const [selectedDobDay, setSelectedDobDay] = useState();
    const [selectedCountry, setSelectedCountry] = useState(countryList[0].country);
    const [gender,setGender] =useState();



    const handleGender = (gender) =>{
        setGender(gender);
    }

    const ListBox = (props) => {
        let value = props.value;
        let setValue = props.setValue;
        let header = props.header;
        let width = props.width;
        return(
            <Listbox value={value} onChange={setValue}>
                <div className={"relative p-0 mt-2 "+width}>
                <Listbox.Button className="relative w-full cursor-default py-2 pl-3 pr-8  md:pr-10 text-left focus:outline-none bg-white-smoke border border-gray-3 rounded-lg h-11">
                    <span className={"block truncate capitalize font-manrope-m text-base  "+(value ? "text-black" : "text-black-2-20  tracking-[.25em]")}>{header}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2  flex item-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 stroke-blue-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {props.children}
                    </Listbox.Options>
                </Transition>
                </div>
            </Listbox>
        );
    }

    return(
        <div className="border border-shuttle-gray-20 rounded-lg">
            <ul role="list" className=" divide-y divide-shuttle-gray-20">
                <li className="flex px-2 md:px-4">
                    <div className="ml-3 py-5">
                        <p className="font-manrope-sb text-base text-blue-1">Personal Details</p>
                        <p className="font-manrope-r text-sm text-gray-1">Your profile is a great way for the doggo days community to start to get to know you.</p>
                    </div>
                </li>
                <li className="flex px-2 md:px-4">
                    <div className="ml-3 py-5">
                        <p className="font-manrope-sb text-base text-blue-1">Your Profile Photo</p>
                        <p className="font-manrope-r text-sm text-gray-1">We would suggest choosing a well-lit photo preferably sunglasses.</p>
                        <div className="flex mt-4">
                            <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" />
                            <div className="flex w-full px-3 items-center gap-3">
                                <button className="w-4/12 h-9 bg-YInMn_Blue_20 rounded-full text-blue-1 font-manrope-m text-sm">Upload</button>
                                <button className="text-gray-1-75 h-9 w-4/12 border-2 border-gray-1-75 rounded-full font-manrope-m text-sm">Remove</button>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="flex px-2 md:px-4">
                    <div className="ml-3 py-5">
                        <p className="font-manrope-sb text-base text-blue-">About</p>
                        <p className="font-manrope-r text-sm text-gray-1">Here you can view and edit your personal details. It's really important that you keep these up to date!</p>
                    </div>
                </li>
                <li className="flex px-2 md:px-4">
                    <div className="w-full mt-7 mx-3">
                        <div className="block md:flex w-full gap-4">
                            <div className="w-full md:w-1/2 mb-3 md:mb-0">
                                <label className="font-manrope-m text-sm text-blue-1">Name*</label>
                                <input type="text" className="h-11 w-full bg-gray-3 rounded-lg px-3 mt-2 font-manrope-m text-base focus:ring-offset-0 focus:ring-0" />
                            </div>
                            <div className="w-full md:w-1/2 mb-3 md:mb-0">
                                <label className="font-manrope-m text-sm text-blue-1">Your Email*</label>
                                <input type="text" className="h-11 w-full bg-gray-3 rounded-lg px-3 border-2 border-green-1 mt-2 font-manrope-m text-base focus:ring-offset-0 focus:ring-0"  placeholder="nolan.leanna@yahoo.com"/>
                                <span className="flex text-green justify-center items-center text-sm font-manrope-r ml-auto w-16 mt-2">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.06732 9.20065L5.61732 7.75065C5.4951 7.62843 5.3451 7.56732 5.16732 7.56732C4.98954 7.56732 4.83398 7.63398 4.70065 7.76732C4.57843 7.88954 4.51732 8.0451 4.51732 8.23398C4.51732 8.42287 4.57843 8.57843 4.70065 8.70065L6.60065 10.6006C6.72287 10.7229 6.87843 10.784 7.06732 10.784C7.25621 10.784 7.41176 10.7229 7.53398 10.6006L11.3173 6.81732C11.4395 6.6951 11.5007 6.5451 11.5007 6.36732C11.5007 6.18954 11.434 6.03398 11.3007 5.90065C11.1784 5.77843 11.0229 5.71732 10.834 5.71732C10.6451 5.71732 10.4895 5.77843 10.3673 5.90065L7.06732 9.20065ZM8.00065 14.6673C7.07843 14.6673 6.21176 14.4922 5.40065 14.142C4.58954 13.7918 3.88398 13.3169 3.28398 12.7173C2.68398 12.1173 2.2091 11.4118 1.85932 10.6006C1.50954 9.78954 1.33443 8.92287 1.33398 8.00065C1.33398 7.07843 1.5091 6.21176 1.85932 5.40065C2.20954 4.58954 2.68443 3.88398 3.28398 3.28398C3.88398 2.68398 4.58954 2.2091 5.40065 1.85932C6.21176 1.50954 7.07843 1.33443 8.00065 1.33398C8.92287 1.33398 9.78954 1.5091 10.6006 1.85932C11.4118 2.20954 12.1173 2.68443 12.7173 3.28398C13.3173 3.88398 13.7924 4.58954 14.1426 5.40065C14.4929 6.21176 14.6678 7.07843 14.6673 8.00065C14.6673 8.92287 14.4922 9.78954 14.142 10.6006C13.7918 11.4118 13.3169 12.1173 12.7173 12.7173C12.1173 13.3173 11.4118 13.7924 10.6006 14.1426C9.78954 14.4929 8.92287 14.6678 8.00065 14.6673Z" fill="#00C38A"/>
                                    </svg>
                                    Verified
                                </span>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 block">
                            <label className="font-manrope-m text-sm text-blue-1">Phone Number*</label>
                            <input type="text" className="h-11 w-full bg-gray-3 rounded-lg px-3 border-2 border-red-1 mt-2 font-manrope-m text-base focus:ring-offset-0 focus:ring-0"  value="7 7010 1111"/>
                            <span className="flex text-red justify-end items-center text-sm font-manrope-r ml-auto w-44 gap-2 mt-2">   
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.665 0C8.43267 0 10.1279 0.702203 11.3779 1.95213C12.6278 3.20206 13.33 4.89733 13.33 6.665C13.33 8.43267 12.6278 10.1279 11.3779 11.3779C10.1279 12.6278 8.43267 13.33 6.665 13.33C4.89733 13.33 3.20206 12.6278 1.95213 11.3779C0.702203 10.1279 0 8.43267 0 6.665C0 4.89733 0.702203 3.20206 1.95213 1.95213C3.20206 0.702203 4.89733 0 6.665 0ZM6.665 5.85663L5.11682 4.30845C5.00962 4.20125 4.86423 4.14103 4.71263 4.14103C4.56103 4.14103 4.41564 4.20125 4.30845 4.30845C4.20125 4.41564 4.14103 4.56103 4.14103 4.71263C4.14103 4.86423 4.20125 5.00962 4.30845 5.11682L5.85663 6.665L4.30845 8.21318C4.25537 8.26626 4.21326 8.32928 4.18454 8.39863C4.15581 8.46798 4.14103 8.5423 4.14103 8.61737C4.14103 8.69243 4.15581 8.76676 4.18454 8.83611C4.21326 8.90546 4.25537 8.96848 4.30845 9.02155C4.36152 9.07463 4.42454 9.11674 4.49389 9.14546C4.56324 9.17419 4.63757 9.18897 4.71263 9.18897C4.78769 9.18897 4.86202 9.17419 4.93137 9.14546C5.00072 9.11674 5.06374 9.07463 5.11682 9.02155L6.665 7.47337L8.21318 9.02155C8.26626 9.07463 8.32928 9.11674 8.39863 9.14546C8.46798 9.17419 8.5423 9.18897 8.61737 9.18897C8.69243 9.18897 8.76676 9.17419 8.83611 9.14546C8.90546 9.11674 8.96848 9.07463 9.02155 9.02155C9.07463 8.96848 9.11674 8.90546 9.14546 8.83611C9.17419 8.76676 9.18897 8.69243 9.18897 8.61737C9.18897 8.5423 9.17419 8.46798 9.14546 8.39863C9.11674 8.32928 9.07463 8.26626 9.02155 8.21318L7.47337 6.665L9.02155 5.11682C9.07463 5.06374 9.11674 5.00072 9.14546 4.93137C9.17419 4.86202 9.18897 4.78769 9.18897 4.71263C9.18897 4.63757 9.17419 4.56324 9.14546 4.49389C9.11674 4.42454 9.07463 4.36152 9.02155 4.30845C8.96848 4.25537 8.90546 4.21326 8.83611 4.18454C8.76676 4.15581 8.69243 4.14103 8.61737 4.14103C8.5423 4.14103 8.46798 4.15581 8.39863 4.18454C8.32928 4.21326 8.26626 4.25537 8.21318 4.30845L6.665 5.85663Z" fill="#F44336"/>
                                </svg>
                                Click here to verify
                            </span>
                            <p className="font-manrope-r text-xs text-gray-1 mt-2">For your safety and security, your phone number will not be shared until you choose to exchange contact details or a payment is made.</p>
                        </div>
                        <div className="w-full mt-7">
                            <div className="w-full mb-3 block xl:flex gap-4">
                                <div className="w-full xl:w-1/2 mb-4 relative  z-[10]">
                                    <label className="font-manrope-m text-sm text-blue-1">Birthdate*</label>
                                    <div className="flex gap-2">
                                        <ListBox 
                                            value={selectedDobYear} 
                                            setValue={setSelectedDobYear} 
                                            header={selectedDobYear ? selectedDobYear : "YYYY"}
                                            width ={"w-3/4"}
                                        >
                                            {Array(82).fill(1).map((year, index) => (
                                                <Listbox.Option
                                                key={index}
                                                className={({ active }) =>
                                                    "relative cursor-default select-none py-2 px-4 font-manrope-m "+( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                                                }
                                                value={2005 - index}
                                                >
                                                {({ selected }) => (
                                                    <>
                                                    <span
                                                        className={`block truncate ${
                                                        selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                    >
                                                        {2005 - index}
                                                    </span>
                                                    </>
                                                )}
                                                </Listbox.Option>
                                            ))}
                                        </ListBox>
                                        <ListBox 
                                            value={selectedDobMonth} 
                                            setValue={setSelectedDobMonth} 
                                            header={selectedDobMonth ? selectedDobMonth : "MM"}
                                            width ={"w-3/4"}
                                        >
                                            {dobMonthList.map((month, index) => (
                                                <Listbox.Option
                                                key={index}
                                                className={({ active }) =>
                                                    "relative cursor-default select-none py-2 px-4 font-manrope-m "+( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                                                }
                                                value={month.month}
                                                >
                                                {({ selected }) => (
                                                    <>
                                                    <span
                                                        className={`block truncate ${
                                                        selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                    >
                                                        {month.month}
                                                    </span>
                                                    </>
                                                )}
                                                </Listbox.Option>
                                            ))}
                                        </ListBox>
                                        <ListBox 
                                            value={selectedDobDay} 
                                            setValue={setSelectedDobDay} 
                                            header={selectedDobDay ? selectedDobDay : "DD"}
                                            width ={"w-3/4"}
                                        >
                                            {Array(31).fill(1).map((day, index) => (
                                                <Listbox.Option
                                                key={index}
                                                className={({ active }) =>
                                                    "relative cursor-default select-none py-2 px-4 font-manrope-m "+( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                                                }
                                                value={index + 1}
                                                >
                                                {({ selected }) => (
                                                    <>
                                                    <span
                                                        className={`block truncate ${
                                                        selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                    >
                                                        {index + 1}
                                                    </span>
                                                    </>
                                                )}
                                                </Listbox.Option>
                                            ))}
                                        </ListBox>
                                        
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/2 mb-4">
                                    <label className="font-manrope-m text-sm text-blue-1">Gender*</label>
                                    <div className="flex gap-4">
                                        {['male','female'].map((data,index) =>
                                            <div  
                                                className={"cursor-pointer h-11 w-1/2 bg-white-smoke rounded-lg px-3 mt-2 flex items-center " +(gender == data ? "border-2 border-blue-1" :"border border-gray-3" )} 
                                                onClick={(e) => handleGender(data)}         
                                            >
                                                <input 
                                                    type="radio" 
                                                    name="gender" 
                                                    className={"mr-3 text-blue-1 focus:ring-2 focus:ring-blue-1 " 
                                                            +(gender == data ? "ring-2 ring-blue-1 ring-offset-2" : "")} 
                                                    value={data}
                                                    checked={gender == data ? true : false}
                                                />
                                                <span className="font-manrope-m text-sm text-blue-1 capitalize">{data}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mb-3 block md:flex gap-4">
                                <div className="w-full md:w-1/2 mb-3 md:mb-0">
                                    <label className="font-manrope-m text-sm text-blue-1">Address*</label>
                                    <input type="text" className="h-11 w-full bg-white-smoke border border-gray-3 rounded-lg px-3 mt-2 font-manrope-m text-base focus:ring-offset-0 focus:ring-0" />
                                </div>
                                <div className="w-full md:w-1/2">
                                    <label className="font-manrope-m text-sm text-blue-1">Suburb*</label>
                                    <input type="text" className="h-11 w-full bg-white-smoke border border-gray-3 rounded-lg px-3 mt-2 font-manrope-m text-base focus:ring-offset-0 focus:ring-0" />
                                </div>
                            </div>
                            <div className="w-full mb-3 block md:flex gap-4">
                                <div className="w-full md:w-1/2 mb-3 md:mb-0">
                                    <label className="font-manrope-m text-sm text-blue-1">City*</label>
                                    <input type="text" className="h-11 w-full bg-white-smoke border border-gray-3 rounded-lg px-3 mt-2 font-manrope-m text-base focus:ring-offset-0 focus:ring-0" />
                                </div>
                                <div className="w-full md:w-1/2  relative  z-[8]">
                                    <label  className="font-manrope-m text-sm text-blue-1">Country*</label>
                                    <ListBox 
                                        value={selectedCountry} 
                                        setValue={setSelectedCountry} 
                                        header={selectedCountry ? selectedCountry : "Country"}
                                        width ={"w-full"}
                                    >
                                        {countryList.map((country, index) => (
                                            <Listbox.Option
                                            key={index}
                                            className={({ active }) =>
                                                "relative cursor-default select-none py-2 px-4 font-manrope-m "+( active ? 'bg-white-smoke text-blue-1' : 'text-gray-1')
                                            }
                                            value={country.country}
                                            >
                                            {({ selected }) => (
                                                <>
                                                <span
                                                    className={`block truncate ${
                                                    selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                                >
                                                    {country.country}
                                                </span>
                                                </>
                                            )}
                                            </Listbox.Option>
                                        ))}
                                    </ListBox>
                                </div>
                            </div>
                            <div className="w-full gap-4 md:pr-4 mb-3">
                                <div className="w-1/2">
                                    <label className="font-manrope-m text-sm text-blue-1">postcode*</label>
                                    <input type="text" className="h-11 w-full bg-white-smoke border border-gray-3 rounded-lg px-3 mt-2 font-manrope-m text-base focus:ring-offset-0 focus:ring-0" />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="w-full mb-4">
                                <label className="font-manrope-m text-sm text-blue-1">Your Bio*</label>
                                <textarea rows="5" placeholder="Tell us a bit about yourself and the needs of your pets." className="w-full bg-white-smoke border border-gray-3 rounded-lg p-3 mt-2 font-manrope-m text-base placeholder:text-base placeholder:font-manrope-r placeholder:text-gray-1-75"></textarea>
                            </div>
                        </div>
                    </div>
                </li>
                <div className="flex my-2 border-none px-5 md:px-7  w-full md:w-7/12  lg:w-5/12 gap-3 mb-5 border">
                    <button className="text-primary h-11 w-1/2 border-2 border-primary rounded-full font-manrope-b text-base">Cancel</button>
                    <button className="w-1/2 h-11 bg-primary rounded-full text-white font-manrope-b text-base">Save</button>
                </div>
            </ul>
        </div>
    )
}
export default PersonalDetails;