
function PetsList(){
    return(
        <div className="border border-shuttle-gray-20 rounded-lg">
            <ul role="list" className=" divide-y divide-shuttle-gray-20">
                <li className="flex px-2 md:px-4">
                    <div className="ml-3 py-5">
                        <p className="font-manrope-sb text-base text-blue-1 mb-1">Pets</p>
                        <p className="font-manrope-r text-sm text-gray-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </li>
                <li className="flex px-2 py-8 ">
                    <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-8 mb-7 mx-3">
                        <div className="w-full border flex items-center relative p-4 rounded-xl bg-orange-10 border-dashed border-2 border-orange">
                            <div className="w-24 h-28">
                                <figure className="w-full h-28">
                                    <img src="https://images.unsplash.com/photo-1621101164063-ba88826cb918?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NTl8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=600&q=60" class="w-full h-full object-cover object-center rounded-xl" alt="" />
                                </figure>
                            </div>
                            <div className="w-6/12 p-2 pl-4">
                                <h3 className="text-blue-1 font-manrope-sb text-xl mb-2">Oliver</h3>
                                <p className="text-blue-1 font-manrope-r text-sm mb-6">Affengriffon</p>
                                <p className="text-blue-1 font-manrope-m text-base">2.5 years</p>
                            </div>
                            <div className="absolute bottom-0 right-0 p-5 py-5 flex justify-between">
                                <div className="flex items-center justify-end">
                                    <span className="mx-2">                                   
                                        <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.33333 15.8005C2.96667 15.8005 2.65267 15.6699 2.39133 15.4085C2.13044 15.1476 2 14.8339 2 14.4672V5.13385C2 4.76719 2.13044 4.45319 2.39133 4.19185C2.65267 3.93097 2.96667 3.80052 3.33333 3.80052H9.28333L7.95 5.13385H3.33333V14.4672H12.6667V9.83385L14 8.50052V14.4672C14 14.8339 13.8696 15.1476 13.6087 15.4085C13.3473 15.6699 13.0333 15.8005 12.6667 15.8005H3.33333ZM10.7833 4.18385L11.7333 5.11719L7.33333 9.51719V10.4672H8.26667L12.6833 6.05052L13.6333 6.98385L9.21667 11.4005C9.09444 11.5227 8.95289 11.6201 8.792 11.6925C8.63067 11.7645 8.46111 11.8005 8.28333 11.8005H6.66667C6.47778 11.8005 6.31956 11.7367 6.192 11.6092C6.064 11.4812 6 11.3227 6 11.1339V9.51719C6 9.33941 6.03333 9.16985 6.1 9.00852C6.16667 8.84763 6.26111 8.70608 6.38333 8.58385L10.7833 4.18385ZM13.6333 6.98385L10.7833 4.18385L12.45 2.51719C12.7167 2.25052 13.0362 2.11719 13.4087 2.11719C13.7807 2.11719 14.0944 2.25052 14.35 2.51719L15.2833 3.46719C15.5389 3.72274 15.6667 4.03385 15.6667 4.40052C15.6667 4.76719 15.5389 5.0783 15.2833 5.33385L13.6333 6.98385Z" fill="#061257"/>
                                        </svg>
                                    </span>
                                    <span className="text-blue-1 font-manrope-r text-base">
                                        Edit
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full border flex items-center relative p-4 rounded-xl bg-gray-3 border-dashed border-2 border-shuttle-gray-20">
                            <div className="w-24 h-28">
                                <figure className="w-full h-28">
                                    <img src="https://images.unsplash.com/photo-1507660392550-9aff6e04c7e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80" class="w-full h-full object-cover object-center rounded-xl" alt="" />
                                </figure>
                            </div>
                            <div className="w-6/12 p-2 pl-4">
                                <h3 className="text-blue-1 font-manrope-sb text-xl mb-2">Morgana</h3>
                                <p className="text-blue-1 font-manrope-r text-sm mb-6">Bichonaranian</p>
                                <p className="text-blue-1 font-manrope-m text-base">2.5 years</p>
                            </div>
                            <div className="absolute bottom-0 right-0 p-5 py-5 flex justify-between">
                                <div className="flex items-center justify-end">
                                    <span className="mx-2">                                   
                                        <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.33333 15.8005C2.96667 15.8005 2.65267 15.6699 2.39133 15.4085C2.13044 15.1476 2 14.8339 2 14.4672V5.13385C2 4.76719 2.13044 4.45319 2.39133 4.19185C2.65267 3.93097 2.96667 3.80052 3.33333 3.80052H9.28333L7.95 5.13385H3.33333V14.4672H12.6667V9.83385L14 8.50052V14.4672C14 14.8339 13.8696 15.1476 13.6087 15.4085C13.3473 15.6699 13.0333 15.8005 12.6667 15.8005H3.33333ZM10.7833 4.18385L11.7333 5.11719L7.33333 9.51719V10.4672H8.26667L12.6833 6.05052L13.6333 6.98385L9.21667 11.4005C9.09444 11.5227 8.95289 11.6201 8.792 11.6925C8.63067 11.7645 8.46111 11.8005 8.28333 11.8005H6.66667C6.47778 11.8005 6.31956 11.7367 6.192 11.6092C6.064 11.4812 6 11.3227 6 11.1339V9.51719C6 9.33941 6.03333 9.16985 6.1 9.00852C6.16667 8.84763 6.26111 8.70608 6.38333 8.58385L10.7833 4.18385ZM13.6333 6.98385L10.7833 4.18385L12.45 2.51719C12.7167 2.25052 13.0362 2.11719 13.4087 2.11719C13.7807 2.11719 14.0944 2.25052 14.35 2.51719L15.2833 3.46719C15.5389 3.72274 15.6667 4.03385 15.6667 4.40052C15.6667 4.76719 15.5389 5.0783 15.2833 5.33385L13.6333 6.98385Z" fill="#061257"/>
                                        </svg>
                                    </span>
                                    <span className="text-blue-1 font-manrope-r text-base">
                                        Edit
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full border flex p-4 rounded-xl bg-gray-3 border-dashed border-2 border-shuttle-gray-20 h-36">
                            <button className="flex items-center justify-center m-auto">
                                <span>                                  
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.75 23.75V16.25H6.25V13.75H13.75V6.25H16.25V13.75H23.75V16.25H16.25V23.75H13.75Z" fill="#62686E"/>
                                    </svg>
                                </span>
                                <span className="text-shuttle-gray font-manrope-m text-xl">Add Dog</span>
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}
export default PetsList;