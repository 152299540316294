import Header from "../Components/Header";
import Footer from "../Components/Footer";

function ContactUs () {

    return (
        <>
        <Header/>
            <div className="container mt-28">
                <div className="row">
                    <div className="w-11/12 mx-auto mt-10 mb-24 max-md:w-full max-md:px-5 pt-8">
                        <h2 className="text-4xl font-manrope-b text-blue-1 mb-6">Contact Us</h2>
                        <p className="max-md:w-full md:w-2/3 text-base font-manrope-r text-blue-1 mb-6 lg:w-1/3 leading-relaxed">For urgent matters, please send us an email below or reach out to us at:</p>
                        <div>
                            <form>
                                <div className="max-md:w-full md:w-11/12 lg:w-9/12">
                                    <div className="max-md:block flex gap-4">
                                        <div className="max-md:w-full w-1/2">
                                            <div className="w-full mb-8 ">
                                                <label className="font-manrope-m text-base text-blue-1">Your name</label>
                                                <input type="text" className="h-11 w-full bg-white-smoke border border-gray-3 font-manrope-m text-base rounded-lg px-3 mt-2 focus:ring-offset-0 focus:ring-0" />
                                            </div>
                                            <div className="w-full mb-8">
                                                <label className="font-manrope-m text-base text-blue-1">Phone number</label>
                                                <input type="text" className="h-11 w-full bg-white-smoke border border-gray-3 font-manrope-m text-base rounded-lg px-3 mt-2 focus:ring-offset-0 focus:ring-0" />
                                            </div>
                                        </div>
                                        <div className="max-md:w-full w-1/2">
                                            <div className="w-full mb-8">
                                                <label className="font-manrope-m text-base text-blue-1">Your email</label>
                                                <input type="mail" className="h-11 w-full bg-white-smoke border border-gray-3 font-manrope-m text-base rounded-lg px-3 mt-2 focus:ring-offset-0 focus:ring-0" />
                                            </div>
                                            <div className="w-full mb-8">
                                                <label className="font-manrope-m text-base text-blue-1">Subject</label>
                                                <input type="text" className="h-11 w-full bg-white-smoke border border-gray-3 font-manrope-m text-base rounded-lg px-3 mt-2 focus:ring-offset-0 focus:ring-0" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mb-8">
                                        <label className="font-manrope-m text-base text-blue-1">Description</label>
                                        <textarea rows="5"  className="w-full bg-white-smoke border border-gray-3 font-manrope-m text-base rounded-lg p-3 mt-2 focus:ring-offset-0 focus:ring-0"></textarea>
                                    </div>
                                </div>
                                <button className="max-md:w-full md:w-1/2 lg:w-1/5 font-manrope-b text-base text-YInMn_Blue  flex items-center h-14 justify-center rounded-full border-[3px] border-YInMn_Blue px-10 mt-10">
                                    Send
                                    <span className="ml-2">                                                      
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_671_44)">
                                        <path d="M1.33341 10.9998L0.166748 9.83317L8.16675 1.83317H1.00008V0.166504H11.0001V10.1665H9.33341V2.99984L1.33341 10.9998Z" fill="#386CB9"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_671_44">
                                        <rect width="11" height="11" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                    </span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="w-11/12 mx-auto mt-10">
                        <div className="max-xl:w-full w-7/12">
                            <h3 className="font-manrope-m text-base text-blue-1 uppercase mb-6">Contact</h3>
                            <div className="max-md:block w-full flex mb-6 gap-8">
                                <div className="max-md:w-full w-1/2">
                                        <h2 className="text-blue-2 font-manrope-b text-lg mb-2">Doggo Days</h2>
                                        <address className="font-manrope-r text-lg text-blue-1 not-italic mb-2 normal-case">
                                            Lorem Ipsum is simply dummy text Canberra ACT-2601
                                        </address>
                                </div>
                                <div className="max-md:w-full  w-1/2">
                                    <div className="mb-2">
                                        <a className="font-manrope-r text-lg text-blue-1" href="tel: (02)62232725">
                                            Phone: 0222 333 444
                                        </a>
                                    </div>
                                    <div className="mb-2">
                                        <a className="font-manrope-r text-lg text-blue-1"  href="mailto: gungahlin@eqcafe.com.au">
                                            Email: admin@doggodays.com.au
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4 className="text-blue-2 font-manrope-b text-lg mb-3">FOLLOW</h4>
                                <div className="w-full flex items-end space-x-4"> 
                                    <a  href="#">
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.93994 6.50002C6.93968 7.03046 6.72871 7.53906 6.35345 7.91394C5.97819 8.28883 5.46937 8.49929 4.93894 8.49902C4.40851 8.49876 3.89991 8.28779 3.52502 7.91253C3.15014 7.53727 2.93968 7.02846 2.93994 6.49802C2.94021 5.96759 3.15117 5.45899 3.52644 5.0841C3.9017 4.70922 4.41051 4.49876 4.94094 4.49902C5.47137 4.49929 5.97998 4.71026 6.35486 5.08552C6.72975 5.46078 6.94021 5.96959 6.93994 6.50002ZM6.99994 9.98002H2.99994V22.5H6.99994V9.98002ZM13.3199 9.98002H9.33994V22.5H13.2799V15.93C13.2799 12.27 18.0499 11.93 18.0499 15.93V22.5H21.9999V14.57C21.9999 8.40002 14.9399 8.63002 13.2799 11.66L13.3199 9.98002Z" fill="#3C3EA9"/>
                                            </svg>
                                        </span>
                                    </a>
                                    <a href="#">
                                        <span>     
                                            <svg width="22" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28001 9.09 5.11001 7.38 3.00001 4.79C2.63001 5.42 2.42001 6.16 2.42001 6.94C2.42001 8.43 3.17001 9.75 4.33001 10.5C3.62001 10.5 2.96001 10.3 2.38001 10V10.03C2.38001 12.11 3.86001 13.85 5.82001 14.24C5.19074 14.4122 4.5301 14.4362 3.89001 14.31C4.16161 15.1625 4.69355 15.9084 5.41102 16.4429C6.1285 16.9775 6.99545 17.2737 7.89001 17.29C6.37364 18.4904 4.494 19.1393 2.56001 19.13C2.22001 19.13 1.88001 19.11 1.54001 19.07C3.44001 20.29 5.70001 21 8.12001 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z" fill="#3C3EA9"/>
                                            </svg>
                                        </span>
                                    </a>    
                                    <a  href="#">
                                        <span>           
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.397 20.997V12.801H16.162L16.573 9.59205H13.397V7.54805C13.397 6.62205 13.655 5.98805 14.984 5.98805H16.668V3.12705C15.8486 3.03924 15.025 2.99684 14.201 3.00005C11.757 3.00005 10.079 4.49205 10.079 7.23105V9.58605H7.332V12.795H10.085V20.997H13.397Z" fill="#3C3EA9"/>
                                            </svg>
                                        </span>
                                    </a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Footer/>
        </>
    )
}
export default ContactUs;