
import { Menu, Transition } from '@headlessui/react'
import { useState } from 'react';
import { Link } from "react-router-dom";


function Header(){
    const [showInput,setShowInput] = useState(false);
    const [sideBar,setSideBar] = useState(false);
    return(
       <header className="relative w-full mb-20 z-[100]">
                <div className=" fixed top-0 left-0 right-0 w-full z-[50] bg-white border-b border-white-smoke flex items-center shadow-sm">
                    <div className="container mx-auto w-11/12 mb-2 mt-2">
                        <div className="flex items-center justify-between">
                            <div className=" w-40 h-16">
                            <Link to="/">
                                <img src="/logo.png" className=" w-full h-full" alt="doggo_logo"/>
                            </Link>
                            </div>
                            <div className="right flex items-center w-1/2">
                                <div className={"relative h-12 flex items-center w-full justify-end"+( showInput ? "bg-primary_background rounded-xl" : "")}>
                                    {
                                        showInput &&
                                        <input type="text" name="search" id="search" className="text-sm font-manrope-m rounded-xl pr-16 bg-primary_background h-11 w-full border-0 px-3 focus:ring-offset-0 focus:ring-0" placeholder="Search" />
                                    }
                                    <button onClick={(e) => setShowInput(!showInput)} className="max-md:hidden bg-primary_background absolute top-0 right-0 mt-1  rounded-full  h-10 w-10 my-auto items-center  text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search fill-primary mx-auto stroke-[10px]" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                        </svg>
                                    </button>
                                </div>
                                <Link to="/login">
                                    <button  type="button"  className="max-md:hidden mx-3 capitalize border-2 font-manrope-m text-sm border-primary rounded-full px-3 text-primary h-9 my-auto text-center  md:w-40 lg:w-44">
                                        login / register
                                    </button>
                                </Link>
                                <button onClick={(e) => setSideBar(!sideBar)} className="mx-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-list fill-primary" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            { 
                sideBar && 
                <div className="fixed top-0 right-0 z-[80] w-full">
                    <div className="px-5 relative bg-white w-full h-screen py-8 pt-4">
                        <div className="mb-4">
                            <button onClick={(e) => setSideBar(!sideBar)}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                </span>
                            </button>
                        </div>
                        <div className="relative">
                            <input type="text" name="search" id="search" className="text-sm font-manrope-m rounded-xl bg-primary_background h-11 w-full border-0 px-3 pr-8 focus:ring-offset-0 focus:ring-0" placeholder="Search" />
                            <div className="absolute top-0 right-0 inset-y-0 flex items-center mr-3">
                                <span >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search fill-primary mx-auto stroke-[10px]" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                    </svg>
                                </span>
                            </div>       
                        </div>
                        <div className="my-7">
                            <h3 className="font-manrope-eb text-base text-black mb-2">Services</h3>
                            <ul className="ml-2">
                                <Link to="/dog-walking"><li className="text-sm font-manrope-sb text-blue-1 py-2 uppercase">Dog Walking</li></Link>
                                <Link to="/pet-sitting"><li className="text-sm font-manrope-sb text-blue-1 py-2 uppercase">Pet Sitting</li></Link>
                                <Link to="/pet-hosting"><li className="text-sm font-manrope-sb text-blue-1 py-2 uppercase">Pet Hosting</li></Link>
                                <Link to="/day-care"><li className="text-sm font-manrope-sb text-blue-1 py-2 uppercase">Day Care</li></Link>
                                <Link to="/day-visits"><li className="text-sm font-manrope-sb text-blue-1 py-2 uppercase">Day Visits</li></Link>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <Link to="/about">
                                    <li className="text-base font-manrope-b text-black py-2">About us</li>
                                </Link>
                                <Link to="/events">
                                    <li className="text-base font-manrope-b text-black py-2">Events</li>
                                </Link>
                                <Link to="/contact-us">
                                    <li className="text-base font-manrope-b text-black py-2">Contact us</li>
                                </Link>

                            </ul>
                        </div>
                        <div className="absolute bottom-0 right-0 border-t border-white-smoke w-full px-5 py-3">
                            <button type="button" className="border rounded-xl h-14 w-full font-manrope-m text-base text-white bg-primary">
                                Login/Register         
                            </button>
                        </div>
                    </div>
                </div>
            }
            
       </header> 
    )
}
export default Header;