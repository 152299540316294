import Header from "../Components/Header";
import Footer from "../Components/Footer";


const eventsList =[
    {
        date: "31",
        month : "Jan",
        eventName : "First Fridays Weekend Night Market",
        location : "Huntington Beach, 92647, Australia",
        time : "09.00-03.00",
        description : "Leashed dogs are welcome to this fun night event featuring over 70 vendors with local products, food and produce. There's also live music, food trucks and yard games." 
    },  
    {
        date: "06",
        month : "Feb",
        eventName : "First Fridays Weekend Night Market",
        location : "Huntington Beach, 92647, Australia",
        time : "09.00-03.00",
        description : "Leashed dogs are welcome to this fun night event featuring over 70 vendors with local products." 
    },  
    {
        date: "06",
        month : "Feb",
        eventName : "First Fridays Weekend Night Market",
        location : "Huntington Beach, 92647, Australia",
        time : "09.00-03.00",
        description : "Leashed dogs are welcome to this fun night event featuring over 70 vendors with local products." 
    }

]

function Events (){
    return(
        <>
            <Header/>
            <div className="container mt-28">
                <div className="row">
                    <div className="max-md:px-5 px-6 mt-10">
                        <section>
                            <div className="w-full relative ">
                                <div className="w-full">
                                    <figure className="w-full h-[36em]">
                                        <img src="/images/Rectangle 121.jpg"  className="w-full h-full object-cover rounded-lg" alt=""/>
                                    </figure>
                                </div>
                                <div className="absolute top-0 left-0 w-full h-full flex items-center max-md:px-8 md:pl-16  pl-24 ">
                                    <div className="max-md:w-full md:w-full lg:w-3/4 xl:w-1/2">
                                        <h2 className="text-white max-md:text-6xl text-7xl font-manrope-b">Find a local event near you</h2>
                                        <p className="max-md:text-xl text-2xl text-white font-manrope-m mt-8">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="w-full mt-12">
                                <div className="grid grid-cols-5 gap-2 md:gap-4 lg:gap-2 bg-aliceblue max-md:p-5 md:px-8 md:py-8 p-11 py-16 pb-24 rounded-lg">
                                    <div className="max-md:col-span-5 md:col-span-5 lg:col-span-2">  
                                        <div className="max-xl:w-full w-9/12">
                                            <h2 className="font-manrope-b font-[800] text-4xl capitalize my-4 text-blue-1">Upcoming <span className="text-orange ml-2">Events</span> </h2>
                                            <p className="font-manrope-r text-lg text-blue-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                        </div>
                                    </div>  
                                    <div className="max-md:col-span-5 md:col-span-5 lg:col-span-3"> 
                                        {eventsList.map((event, i) => 
                                        <div className="max-md:block flex w-full relative py-7 px-3 border-b border-blue-1-10 last:border-none">
                                            <div className="max-md:w-full w-20 lg:w-[4.5em] xl:w-20 max-md:text-start text-center">
                                                <h4 className="text-xl font-manrope-m lg:text-base xl:text-xl  text-blue-1"><span className="font-manrope-eb text-xl">{event.date}</span> {event.month}</h4>
                                            </div>
                                            <div className="max-md:w-full max-md:px-0 w-8/12 lg:w-full xl:w-8/12 pl-4 max-md:mt-5">
                                                <h4 className="font-manrope-b text-lg lg:text-base xl:text-lg text-blue-1 mb-3">{event.eventName}</h4>
                                                <div className="max-sm:block flex items-center text-blue-grafy text-base mb-3">
                                                    <p className="max-sm:mb-2 font-manrope-m mr-3 ">{event.location}</p>
                                                    <span className="max-sm:hidden">
                                                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="3" cy="3" r="3" fill="#4EABD6"/>
                                                        </svg>
                                                    </span>
                                                    <span className="max-sm:ml-0 font-manrope-sb ml-3">{event.time}</span>
                                                </div>
                                                <div className="flex items-center my-4">
                                                    <span className="bg-YInMn_Blue_10 px-4 mr-2 rounded-full text-hanblue font-xs h-7 ">
                                                        Nutrition
                                                    </span>
                                                    <span className="bg-YInMn_Blue_10 px-4 mr-2 rounded-full text-hanblue font-xs h-7">
                                                        Grooming
                                                    </span>
                                                </div>
                                                <p className="font-manrope-r text-base text-blue-grafy">{event.description}</p>
                                            </div>
                                            <div className="absolute max-md:top-5 md:top-5 top-9 right-3">
                                                <button className="capitalize font-manrope-sb text-base lg:text-sm xl:text-base text-YInMn_Blue  flex items-center h-10 lg:h-9 xl:h-10  justify-center rounded-full border-2 border-YInMn_Blue px-5">
                                                     book now 
                                                     <span className="ml-2">                                                      
                                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_671_44)">
                                                        <path d="M1.33341 10.9998L0.166748 9.83317L8.16675 1.83317H1.00008V0.166504H11.0001V10.1665H9.33341V2.99984L1.33341 10.9998Z" fill="#386CB9"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_671_44">
                                                        <rect width="11" height="11" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                                        </svg>
                                                     </span>
                                                </button>
                                            </div>
                                        </div>
                                        )}
                                        {
                                            eventsList.length == 0 &&
                                            <div className="w-full text-center p-10">
                                                <figure className="w-2/6 mx-auto">
                                                    <img src="/images/no-events.png"  className="w-full h-full objects-cover" alt=""/>
                                                </figure>
                                                <h4 className="text-blue-1 font-manrope-r text-lg">No Upcoming Events</h4>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section></section>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}
export default Events;